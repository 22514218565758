import React from "react";

export const initialMultipleChoiceClassificationDashboardState = {
  totalClassified: 0,
  totalToClassify: 0,
  handlePopMultipleChoice: () => undefined,
  loadingNewMultipleChoice: false,
};

const MultipleChoiceClassificationDashboardContext = React.createContext({
  ...initialMultipleChoiceClassificationDashboardState,
});

export default MultipleChoiceClassificationDashboardContext;
