import React from "react";
import Card from "../../components/Card";
import MultipleChoiceClassificationDashboard from "../components/MultipleChoiceClassificationDashboard";
import MultipleChoiceClassificationDashboardState from "../states/MultipleChoiceClassificationDashboardState";

const MultipleChoiceClassificationDashboardPage = () => {
  return (
    <Card isFluid>
      <h1 className="text-title">Batimento de Múltipla Escolha</h1>
      <MultipleChoiceClassificationDashboardState>
        <MultipleChoiceClassificationDashboard />
      </MultipleChoiceClassificationDashboardState>
    </Card>
  );
};

export default MultipleChoiceClassificationDashboardPage;
