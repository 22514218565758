import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Form, Scope } from 'informed';
import VerificarLinhasImagensReducer from '../reducers/VerificarLinhasImagensReducer';
import { API_URL } from '../consts';
import { getAxiosConfig } from '../services';
import InputImage from '../v2/classification/components/InputImage';
import Card from '../v2/components/Card';
import Loading from '../v2/components/Loading';


const VerificarLinhasImagens = () => {
  const initialState = {
    images: [],
    loadingFetchImages: false,
    formApi: null,
  };

  const [state, dispatch] = useReducer(VerificarLinhasImagensReducer, initialState);

  const { images, loadingFetchImages } = state;

  const fetchData = async () => {
    const headers = getAxiosConfig();
    const res = await axios.get(`${API_URL}/batch/count-line`, { params: { page_size: 9 }, ...headers });
    dispatch({ type: 'SET_BATIMENTOS', payload: res.data });
  };

  const handleSubmit = async (values) => {
    const headers = getAxiosConfig();
    const data = values.images.map((image, index) => ({ id: state.images[index].id, nr_lines: image.nr_lines }));
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await axios.post(`${API_URL}/batch/count-line`, data, headers);
      fetchData();
    } catch (err) {
      dispatch({ type: 'SET_LOADING', payload: false });
      const status = get(err, 'response.status');
      if (status === 400) {
        toast.error('Ocorreu um erro ao enviar. Verifique se todos os campos estão preenchidos corretamente.');
      } else {
        toast.error('Ocorreu um erro ao enviar. Tente Novamente.');
      }
    }
  };

  useEffect(() => {
    if (state.formApi) {
      fetchData();
    }
  }, [state.formApi]);

  return (
    <>
      <Card isFluid>
        <Loading loading={loadingFetchImages}>
          <section className="validador-images">
            <div className="row">
              <Form
                onSubmit={handleSubmit}
                getApi={(formApi) => dispatch({ type: 'SET_FORM_API', payload: formApi })}
              >
                <div className="row">
                  {images.map((image, index) => (
                    <Scope scope={`images[${index}]`}>
                      <div key={image.id} className="col-4">
                        <InputImage
                          image={image}
                          type="number"
                          field="nr_lines"
                          label="Número de linhas"
                        />
                      </div>
                    </Scope>
                  ))}
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary mt-4" type="submit">
                    Enviar
                  </button>
                </div>
              </Form>
            </div>
          </section>
        </Loading>
      </Card>
    </>
  );
};

export default VerificarLinhasImagens;
