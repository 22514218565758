import React from 'react'
import { Link } from 'react-router-dom'
import imgLogo from '../../../assets/images/logo/Plural.png'


const NavBarLogo = () => (
  <div className="col">
    <figure className="logo-topo">
      <Link to="/">
        <img src={imgLogo} alt="img-logo" className="img-responsive" />
      </Link>
      <img src="" alt="" />
    </figure>
  </div>
)

export default NavBarLogo
