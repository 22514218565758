import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { handleRequestError } from '../services/utils';
import Footer from '../components/Footer';
import { getAxiosConfig } from '../services';
import FooterTable from '../components/FooterTable';
import Loading from '../components/Loading';
import { API_URL } from '../consts';

class AcompanhamentoLote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lotes: [],
      loadingTable: false,
      loadingAction: false,
      total: {},
    };
  }

  componentDidMount() {
    // this.fetchLotes();
    // this.fetchTotal();
  }

  fetchTotal() {
    axios.get(`${API_URL}/lotes-total`, getAxiosConfig(),)
      .then((response) => {
        const total = response.data;
        this.setState({ total });
      })
  }

  handleActionTipo(lote) {
    this.setState({ loadingAction: true });
    const params = {
      lote_id: lote.id,
    };

    axios.post(`${API_URL}/create-lote-tipo`, params)
      .then(() => {
        this.setState({ loadingAction: false });
        this.fetchLotes();
      })
      .catch((error) => {
        handleRequestError(error);
        this.setState({ loadingAction: false });
      });
  }

  handleActionBanco(lote) {
    this.setState({ loadingAction: true });
    const params = {
      lote_id: lote.id,
    };

    axios.post(`${API_URL}/update-database-images`, params)
      .then(() => {
        this.setState({ loadingAction: false });
        this.fetchLotes();
      })
      .catch((error) => {
        handleRequestError(error);
        this.setState({ loadingAction: false });
      });
  }

  fetchLotes() {
    this.setState({ loadingTable: true });
    axios.get(`${API_URL}/lotes`, getAxiosConfig(),)
    .then((response) => {
      this.setState({
        lotes: response.data,
        loadingTable: false,
      });
    })
    .catch((error) => {
      handleRequestError(error);
      this.setState({ loadingTable: false });
    });
  }

  renderActionButton(lote) {
    const { loadingAction } = this.state;
    if (lote.has_action) {
      if (loadingAction) {
        return <Loading />;
      }
      if (lote.tipo) {
        return <button type="button" className="button-atualizar-banco" onClick={() => this.handleActionBanco(lote)}>ATUALIZAR BANCO</button>;
      }
      return <button type="button" className="button-gerar-tipo" onClick={() => this.handleActionTipo(lote)}>GERAR TIPO</button>;
    }
    return '-';
  }

   renderAcompanhamentoLote() {
    const {
      lotes,
      total,
    } = this.state;

    const columns = [
      {
        Header: 'Lote',
        accessor: 'nome',
        minWidth: 200,
        Footer: (
          <FooterTable mediaNal='Total' />
        ),
      },
      {
        Header: 'Situação',
        id: 'situacao',
        accessor: d => d.status.ds_status,
        minWidth: 170,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        Header: 'Imagens',
        accessor: 'tipo.ds_tipo',
        minWidth: 120,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        Header: 'Data Inserido',
        accessor: 'data_inserido',
        minWidth: 110,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        Header: 'Data Processado',
        accessor: 'data_processado',
        minWidth: 110,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        id: 'porcentagens',
        Header: 'Proc. (%)',
        accessor: d => d.porcentagens && `${d.porcentagens}%`,
        minWidth: 80,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        id: 'porcentagens_batimento',
        Header: 'Bat. (%)',
        accessor: d => d.porcentagens_batimento && `${d.porcentagens_batimento}%`,
        minWidth: 80,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
      {
        Header: 'Total',
        accessor: 'nr_total',
        minWidth: 80,
        Footer: (
              <FooterTable mediaNal={total.nr_total || '-'} />
            ),
      },
      {
        Header: 'Resultado do Processamento',
        columns: [
          {
            id: 'redacoes',
            Header: 'Redacoes',
            accessor: 'nr_essay',
            minWidth: 82,
            Footer: (
              <FooterTable mediaNal={total.nr_essay || '-'} />
            ),
          },
          {
            id: 'em_branco',
            Header: 'Em Branco',
            accessor: 'nr_blank',
            minWidth: 91,
            Footer: (
              <FooterTable mediaNal={total.nr_blank || '-'} />
            ),
          },
          {
            id: 'insuficiente',
            Header: 'Insuficiente',
            accessor: 'nr_insufficient',
            minWidth: 96,
            Footer: (
              <FooterTable mediaNal={total.nr_insufficient || '-'} />
            ),
          },
          {
            id: 'revisao',
            Header: 'Revisão',
            accessor: 'nr_review',
            minWidth: 70,
            Footer: (
              <FooterTable mediaNal={total.nr_review || '-'} />
            ),
          },
          {
            id: 'manual',
            Header: 'Manual',
            accessor: 'nr_manual',
            minWidth: 69,
            Footer: (
              <FooterTable mediaNal={total.nr_manual || '-'} />
            ),
          },
          {
            id: 'error',
            Header: 'Erro',
            accessor: 'nr_error',
            minWidth: 69,
            Footer: (
              <FooterTable mediaNal={total.nr_error || '-'} />
            ),
          },
        ],
      },
      {
        Header: 'Ação',
        id: 'botao-acao',
        accessor: d => this.renderActionButton(d),
        resizable: false,
        minWidth: 150,
        Footer: (
          <FooterTable mediaNal='-' />
        ),
      },
    ];


    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <ReactTable
              data={lotes}
              previousText=""
              nextText=""
              pageText="Exibindo"
              ofText="de"
              rowsText="registros"
              defaultPageSize={10}
              className=" -highlight table-acompanhamento-lote"
              columns={columns}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { loadingTable } = this.state;
    return (
      <Fragment>
        <div
          id="tooltip"
          className="tooltip-table"
          style={{
            display: 'none',
            position: 'absolute',
            background: '#fff',
            boxShadow: '0 0 15px rgba(0,0,0,0.2)',
            padding: '10px',
            borderRadius: '6px',
            zIndex: '999',
          }}
        />
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="page-container-border">
            <section>
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <h1 className="text-title">Acompanhamento de Lotes</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
                  <button type="button" disabled={loadingTable} className="button-refresh" onClick={() => this.fetchLotes()}>
                    <i class="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              {
                loadingTable
                  ? <Loading />
                  : this.renderAcompanhamentoLote()
              }
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default AcompanhamentoLote;
