import React from 'react';
import { Select, Option } from 'informed';
import PropTypes from 'prop-types';

class SelectInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    loading: PropTypes.bool,
  }

  static defaultProps = {
    loading: false,
    disabled: false,
    label: '',
    options: [],
    error: '',
    helpBlock: '',
    validate: null,
  };

  render() {
    const {
      label, field, error, helpBlock, validate, disabled, options, loading, className
    } = this.props;

    return (
      <div>
        <label htmlFor="form-state-name">{label}</label>
        <Select
          className={className}
          field={field}
          validate={validate}
          disabled={loading || disabled}
          style={error ? { border: 'solid 1px red' } : null}
        >
          {
            !loading ? (
              <Option value="" disabled>
                selecione
              </Option>
            ) : (
              <Option value="" disabled>
                carregando...
              </Option>
            )
          }
          {
            options ? options.map(item => (
              <Option key={item.value} value={item.value}>{item.label}</Option>
            )) : null
          }
        </Select>
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: 'red' }}>{error}</small> : null}
      </div>
    );
  }
}

export default SelectInput;
