import React from "react";

const DashboardCard = ({ title, children, icon }) => {
  return (
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{title}</h5>
        <div style={{ fontSize: "30pt" }}>
          {children}
          <i className={`fas fa-${icon} float-right`}></i>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
