import React from 'react';
import Rodal from 'rodal';
import PropTypes from 'prop-types';


const ConfirmationClassifyModal = ({
  visible, onClose, result, submitting, onClassify,
}) => (
  <section className="modal-80">
    <Rodal visible={visible} onClose={onClose}>
      <div className="modalConfirmacao">
        <div className="boxTitulo">
          <h1 className="tituloConfirmacao">Alerta</h1>
        </div>
        <p className="conteudoConfirmacao">Deseja realmente classificar essas redações como {result} ?</p>
        <div>
          <div className="float-right">
            <button
              type="button"
              disabled={submitting}
              className="btn btn-primary ml-2"
              onClick={onClassify}
            >
              Sim
            </button>
            <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>
              Não
            </button>
          </div>
        </div>
      </div>
    </Rodal>
  </section>
);

ConfirmationClassifyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  result: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  onClassify: PropTypes.func.isRequired,
};

export default ConfirmationClassifyModal;
