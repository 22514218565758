import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { get } from 'lodash'
import imgLogo from '../assets/images/logo/Plural.png'
import { logout } from '../services'
import { ComponentBase } from '../base'

class NavbarGeral extends ComponentBase {
  constructor( props ) {
    super( props )
    this.state = {
      img: null,
      visualizar_como: true,
      input_visualizar_como: false,
    }
    this.togglePopover = this.togglePopover.bind( this )
    this.openDropdown = this.openDropdown.bind( this )
    this.mostraInputVisualizarComo = this.mostraInputVisualizarComo.bind( this )
    this.handleLogout = this.handleLogout.bind( this )
  }

  // get class popover
  getPopoverClass ( popover ) {
    const { [popover]: isOpen } = this.state
    return isOpen ? 'show' : 'hidden'
  }

  // toggle popover
  togglePopover ( popover ) {
    const { [popover]: isOpen } = this.state
    this.setState( { [popover]: !isOpen } )
    document.body.addEventListener( 'mouseup', ( e ) => {
      if ( e.target.className !== null && e.target.className.indexOf( 'item-popover-fix' ) === -1 ) {
        this.setState(
          { [popover]: false },
        )
      }
    }, true )
  }

  // dropdown menu
  openDropdown ( dropdown ) {
    const { [dropdown]: isOpen } = this.state
    this.setState( { [dropdown]: !isOpen } )
    document.body.addEventListener( 'mouseup', () => {
      this.setState(
        { [dropdown]: false },
      )
    } )
  }

  // popover profile // permissao
  mostraInputVisualizarComo () {
    const { visualizar_como } = this.state
    const { input_visualizar_como } = this.state
    this.setState( { visualizar_como: !visualizar_como } )
    this.setState( { input_visualizar_como: !input_visualizar_como } )
  }

  handleLogout () {
    const { history } = this.props
    logout()
    history.push( '/login' )
  }

  render () {
    const {
      img,
    } = this.state

    const name = get( this.props, 'user.name' )

    return (
      <div>
        <nav className="navbar-nv1">
          <div className="container">
            <div className="row">
              <div className="col">
                <figure className="logo-topo">
                  <Link to="/">
                    <img src={imgLogo} alt="img-logo" className="img-responsive" />
                  </Link>
                  <img src="" alt="" />
                </figure>
              </div>
              <div className="col">
                <div className="info-header">
                  <div className="profile-header">
                    <button
                      type="button"
                      className="btn-profile"
                      onClick={() => this.togglePopover( 'profilePopover' )}
                    >
                      <span className="hidden-xs hidden-sm label-user">{name}</span>
                      <figure className="ml-3">
                        {img
                          ? <img src={img} alt="" className="profile-photo" />
                          : <i className="fas fa-user" />
                        }
                      </figure>
                    </button>
                    <div className={`profile-popover item-popover-fix ${this.getPopoverClass( 'profilePopover' )}`}>
                      <ul>
                        <button
                          onClick={this.handleLogout}
                          type="button"
                          className="btn-logout"
                        >
                          <li>Sair</li>
                        </button>
                      </ul>
                    </div>
                  </div>
                  <div className="visible-xs btn-nav-mobile">
                    <button
                      type="button"
                      className="btn-navbar"
                      onClick={() => this.togglePopover( 'navBarMobile' )}
                    >
                      <i className="fas fa-bars" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <>
          <nav className="navbar-nv2 hidden-xs hidden-sm">
            <div className="container pt-0 pb-0">
              <ul className="navbar-corretor">
                <Link to="/meus-lotes">
                  <li className="navbar-item">Classificação</li>
                </Link>
              </ul>
            </div>
          </nav>
          <nav className={`navbar-nv2-mobile ${this.getPopoverClass( 'navBarMobile' )}`}>
            <div className="container pt-0 pb-0">
              <ul className="navbar-corretor">
                <Link to="/meus-lotes">
                  <li className="navbar-item">Classificação</li>
                </Link>
              </ul>
            </div>
          </nav>
        </>
      </div>
    )
  }
}

NavbarGeral.propTypes = {
  notifications: PropTypes.arrayOf( PropTypes.object ),
}

NavbarGeral.defaultProps = {
  notifications: [],
}


export default withRouter( NavbarGeral )
