import React from 'react';
import PropType from 'prop-types';
import ImageClassificationHeader from './ImageClassificationHeader';
import ListToClassifyImages from './ListToClassifyImages';
import { Loading } from '../../../components';

const ImageClassification = ({
  data, userBatch, onChangeProcessResult, processResult, onCheckImage, fetchingImages,
  onClassifyImages, onChangeCrop,
}) => (
  <div className="image-section">
    <ImageClassificationHeader
      data={data}
      userBatch={userBatch}
      onChangeProcessResult={onChangeProcessResult}
      processResult={processResult}
      onClassifyImages={onClassifyImages}
    />
    {fetchingImages ? <Loading /> : (
      <ListToClassifyImages
        images={data}
        onCheckImage={onCheckImage}
        onChangeCrop={onChangeCrop}
      />
    )}
  </div>
);

ImageClassification.propTypes = {
  data: PropType.arrayOf(
    PropType.shape({
      url: PropType.string,
    }),
  ),
  userBatch: PropType.shape({
    batch_id: PropType.string,
  }),
  onChangeProcessResult: PropType.func.isRequired,
  processResult: PropType.shape({
    label: PropType.string,
    value: PropType.string,
  }).isRequired,
  onCheckImage: PropType.func.isRequired,
  fetchingImages: PropType.bool.isRequired,
  onClassifyImages: PropType.func.isRequired,
  onChangeCrop: PropType.func.isRequired,
};

ImageClassification.defaultProps = {
  data: [],
  userBatch: {},
};

export default ImageClassification;
