import MultipleChoiceClassificationDashboardPage from "./pages/MultipleChoiceClassificationDashboardPage";
import MultipleChoiceClassificationPage from "./pages/MultipleChoiceClassificationPage";

const routes = [
  {
    path: "/multiple-choice-classificiation",
    component: MultipleChoiceClassificationDashboardPage,
    private: true,
    exact: true,
  },
  {
    path: "/multiple-choice-classificiation/:id",
    component: MultipleChoiceClassificationPage,
    private: true,
  },
];

export default routes;
