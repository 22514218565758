import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as pages from './pages';
import Main from './pages/Main';
import './register_css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <>
          <ToastContainer />
          <Switch>
            <Route path="/login" component={pages.Login} />
            <Route path="/404" component={pages.NotFound} />
            <Route path="/login" component={pages.Login} />
            <Main />
          </Switch>
        </>
      </BrowserRouter>
    );
  }
}

export default App;
