import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import axios from "axios";
import { API_URL } from "../../../consts";

const ImageInput = ({ image, onSubmit, onClick, forceValue = undefined }) => {
  const [{ key, loading }, setState] = useState({ key: "", loading: true });
  const [{ value }, setValue] = useState({ value: "" });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(`${API_URL}/batch/image`, {
        key: image.key,
      });
      setState({ loading: false, ...result.data });
    };
    fetchData();
  }, []);

  const onChange = (evt) => {
    setValue({ value: evt.target.value });
  };

  const handleSubmit = (evt, barcode) => {
    evt.preventDefault();
    onSubmit(barcode);
  };

  useEffect(() => {
    setValue({ value: forceValue });
  }, [forceValue]);

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 mb-3">
      {loading ? (
        <figure className="w-100" style={{ height: "200px" }}>
          <Skeleton height="100%" />
        </figure>
      ) : (
        <>
          <form className="row" onSubmit={(evt) => handleSubmit(evt, value)}>
            <input
              disabled={forceValue}
              className="offset-6 col-4 form-control mb-2"
              type="number"
              value={value}
              onChange={onChange}
            />
            <div className="col-2">
              <button
                className="w-100 float-right  btn btn-primary"
                type="submit"
              >
                Enviar
              </button>
            </div>
            <div className="offset-10  col-2">
              <button
                className="w-100 float-right  btn btn-primary"
                type="button"
                onClick={onClick}
              >
                Pular
              </button>
            </div>
          </form>
          <figure>
            <img
              src={key}
              width="100%"
              id="image-authorize"
              className="img-responsive"
              alt="Imagem da prova"
            />
          </figure>
        </>
      )}
    </div>
  );
};

ImageInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
};

export default ImageInput;
