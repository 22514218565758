import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Table from '../../components/Table';


const UserBatchTable = ({
  data, loading, fetchingProgress, location: { pathname },
}) => {
  const renderCell = (d) => {
    if (fetchingProgress) {
      return 'Carregado...';
    }
    return (
      <>
        {`${get(d.value, 'classified')} / ${get(d.value, 'total')}`}
        {get(d.value, 'classified') !== get(d.value, 'total') && (
          <i className="fas fa-exclamation-triangle text-warning ml-2" />
        )}
      </>
    );
  };

  const columns = [
    {
      Header: 'Nome',
      accessor: 'batch_id',
      width: 230,
    },
    {
      Header: 'Progresso',
      columns: [
        {
          Header: 'Redações',
          accessor: 'essay',
          Cell: renderCell,
          className: 'text-center',
        },
        {
          Header: 'Revisão',
          accessor: 'review',
          Cell: renderCell,
          className: 'text-center',
        },
        {
          Header: 'Brancas',
          accessor: 'blank',
          Cell: renderCell,
          className: 'text-center',
        },
        {
          Header: 'Manuais',
          accessor: 'manual',
          Cell: renderCell,
          className: 'text-center',
        },
      ],
    },
    {
      Header: 'Ações',
      accessor: 'id',
      Cell: (accessor) => (
        <Link to={`${pathname}/${accessor.value}`}>
          Classificar
        </Link>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
      defaultPageSize={10}
    />
  );
};

UserBatchTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  fetchingProgress: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

UserBatchTable.defaultProps = {
  data: [],
  loading: false,
};

export default withRouter(UserBatchTable);
