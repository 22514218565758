import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import PropType from 'prop-types';
import { API_URL } from '../../../consts';
import ClassificationHistory from './ClassificationHistory';


const ToClassifyImage = ({ image, onImageClick }) => {

  const [{ key, loading }, setState] = useState({ key: '', loading: true });

  const fetchData = async () => {
    const result = await axios.post(
      `${API_URL}/batch/image`,
      { key: image.original_key },
    );
    setState({ loading: false, ...result.data });
  };

  useEffect(() => {
    fetchData();
  }, [image]);

  const renderImage = () => {
    return (
      <figure>
        <img
          src={key}
          width="100%"
          id="image-authorize"
          className="img-responsive"
          alt="Imagem da prova"
        />
      </figure>
    );
  };

  return (
    <div className={`col-xs-12 col-sm-6 col-md-6 mb-3`}>
      <button
        type="button"
        className={`lista-img-validar w-100`}
        onClick={() => onImageClick()}
      >
        {loading ? (
          <figure className="w-100" style={{ height: '200px' }}>
            <Skeleton height="100%" />
          </figure>
        ) : renderImage()}
        <figcaption>
          <div className="col-12">
            <article className="text-left">
              <span className="validador-txt-destaque">
                {image.enrolment_key}
              </span>
            </article>
          </div>
          <div className="col-12">
            <article className="text-left mt-2">
              <span className="validador-txt-destaque-silver">
                Lote: {" "}
              </span>
              <span>
                {image.batch}
              </span>
            </article>
          </div>
          <ClassificationHistory image={image} />
        </figcaption>
      </button>
    </div>
  );
};

ToClassifyImage.propTypes = {
  image: PropType.shape({
    id: PropType.number,
    check: PropType.bool,
    key: PropType.string,
    enrolment_key: PropType.string,
    batch_name: PropType.string,
    process_result: PropType.string,
  }).isRequired,
  onCheckImage: PropType.func.isRequired,
  onChangeCrop: PropType.func.isRequired,
};

export default ToClassifyImage;
