import React from "react";

const ImageContainer = ({ src }) => {
  return (
    <div>
      <figure>
        <img
          src={src}
          width="100%"
          id="image-authorize"
          className="img-responsive"
          alt="Imagem da prova"
        />
      </figure>
    </div>
  );
};

export default ImageContainer;
