import React from 'react';
import ToClassifyImageDuplicity from './ToClassifyImageDuplicity';


const ClassifyReserveEssay = ({ images, changeNewEssay }) => (
  <section className="validador-images" style={{width:"200%"}}>
    <div className="row">
        <ToClassifyImageDuplicity
          image={images}
          onImageClick={changeNewEssay}
        />
    </div>
  </section>
);


export default ClassifyReserveEssay;
