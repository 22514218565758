import React from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { get } from 'lodash';
import { Footer } from '../components';
import 'react-table/react-table.css';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';
import { handleRequestError } from '../services/utils';

class SituacaoTabelaBatedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_batedores: [],
    };
  }

  componentDidMount() {
    this.fetchBatedor();
  }

  fetchBatedor() {
    axios.get(`${API_URL}/batedores`, getAxiosConfig())
    .then((response) => {
      const { records } = response.data;
      const { lista_batedores } = this.state;
      this.setState({
        lista_batedores: records,
      });
      lista_batedores.map({
        codImagem: get(records, 'image.id'),
        lote: get(records, 'image.lote'),
        data: get(records, 'image.data'),
        validadoRobo: get(records, 'image.validadoRobo'),
        validadoBatedor: get(records, 'image.validadoBatedor'),
        statusAlterado: get(records, 'image.status'),
        acao: get(records, 'image.acao'),
      });
    })
    .catch(handleRequestError);
  }

  printPage(){
    window.print();
    return false;
  }

  render() {
    const data = [{
      codImagem: '123456',
      lote: '#761231',
      data: '05/04/2010',
      validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
      validadoBatedor: <i className="far fa-2x  fa-check-circle check-circle-color" />,
      statusAlterado: 'Folha Dobrada',
      acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
    },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
      {
        codImagem: '123456',
        lote: '#761231',
        data: '05/04/2010',
        validadoRobo: <i className="far fa-2x fa-check-circle check-circle-color" />,
        validadoBatedor: <i className="far fa-2x fa-times-circle times-circle-color" />,
        statusAlterado: '',
        acao: <button type="button" className="btn btn-eye"><i className="fas fa-eye" /></button>,
      },
  ];

    const columns = [{
      Header: 'Código de Imagem',
      accessor: 'codImagem',
    },

    {
      Header: 'Lote',
      accessor: 'lote',
    },

    {
      Header: 'Data',
      accessor: 'data',
    },

    {
      Header: 'Validado Robô',
      accessor: 'validadoRobo',
    },

    {
      Header: 'Validado Batedor',
      accessor: 'validadoBatedor',
    },

    {
      Header: 'Status Alterado',
      accessor: 'statusAlterado',
    },
    
    {
      Header: 'Ação',
        accessor: 'acao',
    },
  ];

    return (
      <section>
        <div className="container">
          <div className="page-container-border">
            <div className="table-situation-title">
              <div className="row">
                <div className="col-md-9 col-xs-12 col-sm-6">
                <h1 className="titulo-tabela-batedor">Situação: Sem texto</h1>
                </div>
                <div className="col-md-3 col-xs-12 col-sm-6">
                  <select className="pull-right form-control input-color">
                    <option>Selecionar problema</option>
                    <option>Sem texto</option>
                    <option>Texto insuficiente</option>
                    <option>Reescanear</option>
                    <option>Ajuste manual</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="table-situation-body">
              <div className="row">
                <button type="button" className="btn btn-primary btn-border">VALIDAR IMAGENS EM BRANCO</button>
              </div>
              <div className="field-filter">
                <div className="row">
                  <div className="col-md-3 col-xs-3 col-sm-3">
                    <p>Por Período</p>
                    <input type="date" className="form-control input-color" />
                  </div>
                  <div className="col-md-3 col-xs-3 col-sm-3">
                    <p>Lote</p>
                    <input type="text" placeholder="Buscar" className="form-control input-color" />
                  </div>
                  <div className="col-md-3 col-xs-3 col-sm-3">
                    <p>Validação</p>
                    <select className="pull-right form-control input-color">
                      <option>Selecionar Status</option>
                      <option>Validado Robô</option>
                      <option>Validado Batedor</option>
                      <option>Validado Batedor e Robô</option>
                    </select>
                  </div>
                  <div className="col-md-3 col-xs-12 col-sm-12">
                    <button type="button" className="btn btn-disabled pull-right printer-button" onClick={() => window.print()}>
                      <i className="fas fa-print pull-right" />
                  </button>
                  </div>
                </div>
              </div>
              <div className="batedor-table">
                <ReactTable
                  data={data}
                  className="-highlight table-situacao"
                  defaultPageSize={4}
                  previousText=''
                  nextText=''
                  noDataText='Sem conteúdo'
                  pageText='Pág.'
                  ofText='de'
                  rowsText='registros'
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

export default SituacaoTabelaBatedor;
