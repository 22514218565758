import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../../consts';

export const fetchUserBatches = (params) => axios.get(`${API_URL}/classification/batches`, { params })
  .then((response) => response.data);

export const fetchUserBatchesProgress = () => axios
  .get(`${API_URL}/classification/batches/progress`)
    .then((response) => response.data);

export const fetchUserBatch = (id) => axios
  .get(`${API_URL}/classification/batches/${id}`)
    .then((response) => response.data);

export const changeToClassify = (id, id_classify, params) => axios
  .patch(`${API_URL}/classification/batches/${id}/to_classify/${id_classify}`, params)
    .then((response) => response.data);

export const fetchToClassifyImages = (id, params) => axios
  .get(`${API_URL}/classification/batches/${id}/to_classify`, { params })
    .then((response) => response.data);

export const classifyImages = (id, data) => axios
  .post(`${API_URL}/classification/batches/${id}/to_classify/classify`, data)
    .then((response) => response.data)
    .catch((err) => {
      toast.error(get(err, 'response.data.images.0', 'Ocorreu um erro desconhecido!'));
      return Promise.reject(new Error());
    });

export const fetchDuplicityEssay = () => axios
  .get(`${API_URL}/batch/duplicity`)
  .then((response) => response.data)
