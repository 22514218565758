import api from "../../../helpers/api";

export const retrieveMultipleChoiceClassification = async (
  multipleChoiceClassificationId
) => {
  const response = await api.get(
    `/classification/multiple-choices/${multipleChoiceClassificationId}`
  );

  return response.data;
};

export const classifyMultipleChoiceClassification = async (
  multipleChoiceClassificationId,
  { result }
) => {
  const response = await api.post(
    `/classification/multiple-choices/${multipleChoiceClassificationId}/classify`,
    { result }
  );
  return response.data;
};

export const updateMultipleChoiceClassification = async (
  multipleChoiceClassificationId,
  { result }
) => {
  const response = await api.patch(
    `/classification/multiple-choices/${multipleChoiceClassificationId}`,
    { result }
  );
  return response.data;
};

export const popMultipleChoiceClassification = async () => {
  const response = await api.post(`/classification/multiple-choices/pop`);
  return response.data;
};

export const fetchMultipleChoiceClassificationDashboard = () => {
  return api.get(`/classification/multiple-choices/dashboard`);
};
