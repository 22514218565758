import React from 'react';
import { RadioGroup, Radio } from 'informed';
import PropTypes from 'prop-types';

class RadioBoxInput extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
  }

  static defaultProps = {
    disabled: false,
    options: [],
    error: '',
    helpBlock: '',
    validate: null,
  }

  render() {
    const {
      field, error, helpBlock, validate, disabled, options,
    } = this.props;

    return (
      <div>
        <RadioGroup field={field} validate={validate}>
          <div className="input-group-custom">
            {options.map(item => (
              <label key={`${item.value}${item.label}`} className="container-radio">
                <Radio value={item.value} id={item.value} disabled={disabled} />
                <span className="checkmark">
                  <span>{item.label}</span>
                </span>
              </label>
            ))}
          </div>
        </RadioGroup>
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: 'red' }}>{error}</small> : null}
      </div>
    );
  }
}

export default RadioBoxInput;
