import { get } from 'lodash';
import axios from 'axios';
import { setAuthorizedToken } from '.';
import { API_URL } from '../consts';


const handleLogin = credentials => axios.post(`${API_URL}/login`, credentials)
  .then((response) => {
    setAuthorizedToken(response.data.token);
    return response.data;
  }).catch((error) => {
    const errorData = new Error();
  if (error.response) {
    if (error.response.status === 400) {
      const errors = get(error, 'response.data', {});
      errorData.data = errors;
      return Promise.reject(errorData);
    }
    errorData.data = { non_field_errors: ['Ocorreu um erro no servidor.'] };
    return Promise.reject(errorData);
  }
  if (error.request) {
    errorData.data = { non_field_errors: ['Erro de conexão com o servidor.'] };
    return Promise.reject();
  }
  errorData.data = { non_field_errors: ['Erro desconhecido.'] };
  return Promise.reject(errorData);
});

export default handleLogin;
