import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';

const CropImage = ({ src, onChange, image }) => {
  const smaller = {
    unit: '%',
    height: 74.5,
    width: 100.0,
    x: 0.0,
    y: 19.5,

  };

  const bigger = {
    unit: '%',
    height: 74.5,
    width: 100.0,
    x: 0.0,
    y: 19.5,
  };

  const initialCropValues = () => {
    if (image.id_ === '292') {
      return bigger;
    }
    return smaller;
  };

  const [crop, setCrop] = useState(initialCropValues());
  const [cropped, setCropped] = useState(false);
  const iconStyle = {
    float: 'right',
  };

  useEffect(() => {
    if (image.crop_data) {
      const crop_data = JSON.parse(image.crop_data);
      setCrop(crop_data);
      setCropped(true);
    }
  }, []);

  const onCropComplete = (completedCrop, percentCrop) => {
    onChange(percentCrop, image, () => setCropped(true));
  };

  const onChangeCallback = (currentCrop, percentCrop) => {
    setCrop(percentCrop);
  };

  return (
    <>
      {cropped ? (<i className="fas fa-check text-success" style={iconStyle}/>) : (<i className="fas fa-times text-danger" style={iconStyle}/>)}
      <ReactCrop
        src={src}
        crop={crop}
        onComplete={onCropComplete}
        onChange={onChangeCallback}
      />
    </>
  );
};

CropImage.propTypes = {
  src: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  image: PropTypes.shape({
    id_: PropTypes.string,
    crop_data: PropTypes.string,
  }).isRequired,
};

export default CropImage;
