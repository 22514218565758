import React from 'react';
import ReactTable from 'react-table';
import PropType from 'prop-types';

const Table = ({
    data, className, previousText, nextText, noDataText, pageText, ofText,
    rowsText, columns, ...rest
}) => (
  <ReactTable
    data={data}
    className={className}
    previousText={previousText}
    nextText={nextText}
    noDataText={noDataText}
    pageText={pageText}
    ofText={ofText}
    rowsText={rowsText}
    columns={columns}
    {...rest}
  />
);

Table.propTypes = {
  data: PropType.arrayOf(PropType.object),
  className: PropType.string,
  previousText: PropType.string,
  nextText: PropType.string,
  noDataText: PropType.string,
  pageText: PropType.string,
  ofText: PropType.string,
  rowsText: PropType.string,
  columns: PropType.arrayOf(PropType.object).isRequired,
};

Table.defaultProps = {
  data: [],
  className: 'table-situacao',
  previousText: ',',
  nextText: ',',
  noDataText: 'Sem conteúdo',
  pageText: 'Pág.',
  ofText: 'de',
  rowsText: 'registros',
};

export default Table;
