import React from 'react';
import loader from '../assets/icons/loading.gif';

const Loading = () => (
  <div className="loading-style text-center w-100">
    <img src={loader} className="loading" alt="Carregando..." />
  </div>
);

export default Loading;
