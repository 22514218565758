import React from "react";

const PopMultipleChoiceButton = ({
  onPopMultipleChoice,
  children,
  disabled,
}) => (
  <button
    type="button"
    className="btn btn-primary"
    onClick={() => onPopMultipleChoice()}
    disabled={disabled}
  >
    {children}
  </button>
);

export default PopMultipleChoiceButton;
