import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import { withRouter } from 'react-router-dom'
import { Form } from 'informed'
import { TextInput, Alert } from '../components'
import { setFormErrors } from '../services/utils'
import imgLogo from '../assets/images/logo/Plural.png'
import handleLogin from '../services/login_service'


class Login extends React.Component {
  state = {
    loggingIn: false,
    non_field_errors: [],
  };

  handleSubmit = ( values ) => {
    this.setState( { loggingIn: true } )
    handleLogin( values )
      .then( ( data ) => {
        const { history } = this.props
        history.push( '/', { data } )
      } ).catch( ( error ) => {
        const { non_field_errors, ...fieldErrors } = error.data
        this.setState( { non_field_errors: non_field_errors || [] } )
        setFormErrors( this.formApi, fieldErrors )
        this.setState( { loggingIn: false } )
      } )
  }

  render () {
    const { loggingIn, non_field_errors } = this.state

    return (
      <section className="login-bg">
        <div className="container">
          <div className="box-form text-center">
            <figure>
              <img src={imgLogo} className="logo-login" alt="logo-fgv" />
            </figure>
            <Form
              onSubmit={this.handleSubmit}
              getApi={( formApi ) => { this.formApi = formApi }}
            >
              <div className="form-sign">
                {non_field_errors.map( error => (
                  <Alert
                    key={shortid.generate()}
                    category="danger"
                    onClick={() => this.setState( { non_field_errors: non_field_errors.filter( e => e !== error ) } )}
                  >
                    {error}
                  </Alert>
                ) )}
                <div className="group-input mt-3">
                  <i className="fas fa-user" />
                  <TextInput
                    placeholder="CPF"
                    field="username"
                  />
                </div>
                <div className="group-input mt-3">
                  <i className="fas fa-lock" />
                  <TextInput
                    placeholder="SENHA"
                    field="password"
                    type="password"
                  />
                </div>

                <div className="action-login mt-3">
                  <button
                    style={{
                      backgroundColor: "#ff7d26",
                      fontSize: "12px",
                      minHeight: "35px",
                      color: "white",
                      fontWeight: "700",
                      border: "none",
                      borderRadius: "40px",
                      color: "white",
                      boxShadow: "2px 5px 4px 0px rgb(58, 58, 58, 25%)"
                    }}
                    type="submit"
                    className="btn bg-default-color-xs"
                    disabled={loggingIn}
                  >
                    {loggingIn ? 'ENTRANDO...' : 'ENTRAR'}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
    )
  }
}

Login.propTypes = {
  history: PropTypes.shape( {
    push: PropTypes.func,
  } ).isRequired,
}

export default withRouter( Login )
