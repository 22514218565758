import React from 'react';
import PropType from 'prop-types';

const PaginationInfo = ({ total_pages, page, total_images }) => (
  <div className="offset-6 col-3">
    <div className="info-pagination p-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
      {
        total_pages === 0 && (
          <span>
            Página: 0 de {total_pages}
          </span>
        )
      }
      {
        total_pages !== 0 && (
          <span>
            Página: {page} de {total_pages}
          </span>
        )
      }
      <span className="ml-4">
        Total de imagens: {total_images}
      </span>
    </div>
  </div>
);

PaginationInfo.propTypes = {
  total_pages: PropType.number,
  page: PropType.number,
  total_images: PropType.number,
};

PaginationInfo.defaultProps = {
  total_pages: 0,
  page: 0,
  total_images: 0,
};

export default PaginationInfo;
