import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URL } from '../../../consts';


const ScreeningInput = ({ image, onSubmit, onClick }) => {
  const [{ key, loading }, setState] = useState({ key: '', loading: true });
  const [{ barcode }, setBarcode] = useState({ barcode: '' });
  const [{ enrolment_key }, setEnrolment] = useState({ enrolment_key: '' });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(
        `${API_URL}/batch/image`,
        { key: image.key },
      );
      setState({ loading: false, ...result.data });
    };
    fetchData();
  }, []);

  const onChange = (evt) => {
    if (evt.target.id === 'barcode') {
      setBarcode({ barcode: evt.target.value });
    } else {
      setEnrolment({ enrolment_key: evt.target.value });
    }
  };

  const handleSubmit = (evt, value) => {
    evt.preventDefault();
    onSubmit(value);
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12 mb-3">
      {loading ? (
        <figure className="w-100" style={{ height: '200px' }}>
          <Skeleton height="100%" />
        </figure>
      ) : (
          <>
            <form onSubmit={(evt) => handleSubmit(evt, { barcode, enrolment_key })}>
              <div className="row">
                <input id="barcode" className="offset-1 col-4 form-control" type="number" placeholder="código de barras" value={barcode} onChange={onChange} />
                <input id="enrollment" className="col-4 form-control" type="number" placeholder="código de inscrição" value={enrolment_key} onChange={onChange} />
                <div className="col-2 mb-2">
                  <button className="w-100 float-right  btn btn-primary" type="submit">Enviar</button>
                </div>
              </div>
              <div className="row">
                <div className="offset-9  col-2">
                  <button className="w-100 float-right  btn btn-primary" type="button" onClick={onClick}>Pular</button>
                </div>
              </div>
            </form>
            <figure>
              <img
                src={key}
                width="100%"
                id="image-authorize"
                className="img-responsive"
                alt="Imagem da prova"
              />
            </figure>
          </>
        )}
    </div>
  );
};

ScreeningInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
};

export default ScreeningInput;
