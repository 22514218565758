import React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "../components";

export const makeRouteComponents = (routes) => {
  return routes.map((route) => {
    const RouteComponent = route.private ? PrivateRoute : Route;
    return (
      <RouteComponent
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    );
  });
};
