import api from "../../../helpers/api";

export const fetchSubscriptions = async (params) => {
  const queryParams = {
    ordering: "name",
    ...params,
  };
  const response = await api.get("/subscriptions", { params: queryParams });
  return response.data;
};

export const retrieveSubscription = async (subscriptionId) => {
  const response = await api.get(`/subscriptions/${subscriptionId}`);
  return response.data;
};
