import { get } from "lodash";
import React, { useState } from "react";
import AsyncProjectInput from "./AsyncProjectInput";
import AsyncSubscriptionInput from "./AsyncSubscriptionInput";

const SubscriptionInputGroup = ({
  onSelectSubscription,
  onSelectProject = () => undefined,
}) => {
  const [project, setProject] = useState(undefined);

  const handleSelectProject = (option) => {
    const projectId = get(option, "value");
    setProject(projectId);
    onSelectProject(projectId);
  };

  const handleSelectSubscription = (option) => {
    const subscription = get(option, "value");
    onSelectSubscription(subscription);
  };

  return (
    <>
      <AsyncProjectInput onChange={handleSelectProject} />
      <AsyncSubscriptionInput
        disabled={!!!project}
        onChange={handleSelectSubscription}
        fetchParams={{ project }}
      />
    </>
  );
};

export default SubscriptionInputGroup;
