import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import axios from 'axios';
import { Form } from 'informed';
import { toast } from 'react-toastify';
import { Footer, SelectInput, Loading } from '../components';
import { getAxiosConfig } from '../services';
import { handleRequestError } from '../services/utils';
import { toastConfig } from '../utils';
import { API_URL, ATRIBUI } from '../consts';

class AtribuiImagemBatedor extends React.Component {
  constructor(props) {
    super(props);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.fetchLotes = this.fetchLotes.bind(this);
    this.filterLotes = this.filterLotes.bind(this);
    this.filterLotesPendentes = this.filterLotesPendentes.bind(this);
    this.filterLotesNaoMovidos = this.filterLotesNaoMovidos.bind(this);
    this.atribuiImagem = this.atribuiImagem.bind(this);
    this.state = {
      loadingAtribuiImagens: false,
      loadingTable: true,
      filters: null,
      users: [],
      lotes: [],
      usuarioLotes: [],
    };
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchLotes();
    this.fetchUsuarioLotes();
  }

  atribuiImagem = () => {
    const { atribuiFormState, loadingAtribuiImagens } = this.state;
    if (loadingAtribuiImagens) {
      return <Loading />;
    }
    const data = {
      id_lote: atribuiFormState.values.batch,
      id_user: atribuiFormState.values.username,
      tipo: atribuiFormState.values.result,
    };
    if (data.id_lote !== undefined && data.id_user !== undefined) {
      this.setState({ loadingAtribuiImagens: true });
      axios
        .post(`${API_URL}/batimento/atribui`, data, getAxiosConfig())
        .then(() => {
          this.setState({ atribuiFormState, loadingAtribuiImagens: false });
          window.location.href = '/atribui';
          toast.success('Imagem atribuida com sucesso.', toastConfig);
        })
        .catch(() => {
          this.setState({ atribuiFormState, loadingAtribuiImagens: false });
          toast.error('Conjunto de imagem já atribuido ao usuário.', toastConfig);
        });
    } else {
      this.setState({ atribuiFormState, loadingAtribuiImagens: false });
      toast.error('Dados insuficientes', toastConfig);
    }
  }


  fetchUsers() {
    axios.get(`${API_URL}/batimento/usuarios`, getAxiosConfig())
    .then((response) => {
      const users = [];
      for (let i = 0; i < response.data.results.length; i++) {
        users.push(({ label: response.data.results[i].username, value: response.data.results[i].id }));
      }
      this.setState({ users });
    }).catch(handleRequestError);
  }

  fetchLotes() {
    axios.get(`${API_URL}/lotes_batimento`, getAxiosConfig())
    .then((response) => {
      const lotes = response.data.map(lote => ({ label: lote.nome, value: lote.id }));
      this.setState({ lotes });
    }).catch(handleRequestError);
  }

  filterLotesPendentes() {
    const filters = 'pendentes';
    this.setState({ filters },
      () => { this.fetchUsuarioLotes(); });
  }

  filterLotesNaoMovidos() {
    const filters = 'nao_movidas';
    this.setState({ filters },
      () => { this.fetchUsuarioLotes(); });
  }

  filterLotes() {
    const filters = null;
    this.setState({ filters },
      () => { this.fetchUsuarioLotes(); });
  }

  fetchUsuarioLotes() {
    this.setState({ loadingTable: true });

    const { filters } = this.state;
    const { headers } = getAxiosConfig();
    axios.get(`${API_URL}/batimento/usuario_lote`,
    { params: { filters }, headers })
    .then((response) => {
      this.setState({
        usuarioLotes: response.data.results,
        loadingTable: false,
      });
    }).catch((error) => {
      handleRequestError(error);
      this.setState({ loadingTable: false });
    });
  }

  renderAcompanhamentoLote() {
    const { usuarioLotes } = this.state;

    const columns = [
      {
        Header: 'Resultado do Processamento',
        columns: [
          {
            Header: 'Lote',
            accessor: 'lote.nome',
          },
          {
            Header: 'Situação',
            id: 'situacao',
            accessor: 'situacao',
          },
          {
            Header: 'Usuário',
            id: 'usuario',
            accessor: 'user.username',
          },
          {
            Header: 'Imagens Pendentes',
            id: 'imagens_pendentes',
            accessor: 'nr_pendentes',
          },
          {
            Header: 'Imagens Batidas',
            id: 'imagens_batidas',
            accessor: 'nr_batidas',
          },
          {
            Header: 'Batimento (%)',
            id: 'taxa_conclusao',
            accessor: 'porcentagens',
          },
          {
            Header: 'Data atribuição',
            id: 'data_atr',
            accessor: 'data_created',
          },
          {
            Header: 'Data modificação',
            id: 'data_update',
            accessor: 'data',
          },
          {
            Header: 'Mover',
            id: 'mover',
            accessor: 'show_button_mover',
          },
        ],
      },
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <ReactTable
              data={usuarioLotes}
              previousText=""
              nextText=""
              pageText="Exibindo"
              ofText="de"
              rowsText="registros"
              defaultPageSize={10}
              className=" -highlight table-acompanhamento-lote"
              columns={columns}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      users,
      lotes,
      loadingAtribuiImagens,
      loadingTable,
    } = this.state;
    if (loadingAtribuiImagens) {
      return <Loading />;
    }
    return (
      <Fragment>
        <div style={{ paddingLeft: 100, paddingRight: 100 }}>
          <div className="page-container-border">
            <section className="section-search">
              <div className="filter mb-5">
                  <Form
                    onChange={atribuiFormState => this.setState({ atribuiFormState })}
                    onValueChange={this.onValueChange}
                    getApi={(formApi) => { this.formApi = formApi; }}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <SelectInput
                          name="username"
                          field="username"
                          options={users}
                          label="Usuário: "
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4">
                        <SelectInput
                          name="lote"
                          field="batch"
                          options={lotes}
                          label="Lote: "
                          className="form-control"
                          multiple
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectInput
                          name="tipo"
                          field="result"
                          options={ATRIBUI.SITUACAO}
                          label="Situação: "
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-2 pt-2">
                        <button onClick={this.atribuiImagem} type="submit" className="btn btn-primary mt-4  w-100">
                          Atribuir
                        </button>
                      </div>
                    </div>
                  </Form>
              </div>
            </section>
            <div className="image-section">
              <div className="buttons-validate">
                <ul className="validador-lista-actions">
                  <li>
                    <label className="mt-2 text-right">FILTRAR POR:</label>
                  </li>
                  <li>
                    <button onClick={this.filterLotesPendentes} type="button" className="btn btn-primary" disabled={loadingTable}>
                      Atribuições Pendentes
                    </button>
                  </li>
                  <li>
                    <button onClick={this.filterLotesNaoMovidos} type="button" className="btn btn-primary" disabled={loadingTable}>
                      Batimentos Completos Não Movidos
                    </button>
                  </li>
                  <li>
                    <button onClick={this.filterLotes} type="button" className="btn btn-primary" disabled={loadingTable}>
                      Visão Geral
                    </button>
                  </li>
                </ul>
              </div>
              {
                loadingTable
                ? <Loading />
                : this.renderAcompanhamentoLote()
              }
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default AtribuiImagemBatedor;
