const VerificarLinhasImagensReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BATIMENTOS':
      return {
        ...state,
        images: action.payload,
        loadingFetchImages: false,
      };
    case 'SET_FORM_API':
      return {
        ...state,
        formApi: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loadingFetchImages: action.payload,
      };
    default: return state;
  }
};

export default VerificarLinhasImagensReducer;
