import React from "react";
import Card from "../../components/Card";
import MultipleChoiceClassification from "../components/MultipleChoiceClassification";
import MultipleChoiceClassificationState from "../states/MultipleChoiceClassificationState";

const MultipleChoiceClassificationPage = () => {
  return (
    <MultipleChoiceClassificationState>
      <Card isFluid>
        <h1 className="text-title">Batimento de Múltipla Escolha</h1>
        <MultipleChoiceClassification />
      </Card>
    </MultipleChoiceClassificationState>
  );
};

export default MultipleChoiceClassificationPage;
