import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';
import NavBar from '../v2/navbar/components/NavBar';
import UserContext from '../v2/contexts/UserContext';

const PrivateRoute = ({ navbar, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
    isAuthenticated()
      ? (
        <UserContext.Consumer>
          {(user) => (
            <>
              {navbar && <NavBar user={user} />}
              <Component {...props} user={user} />
            </>
          )}
        </UserContext.Consumer>
      )
      : <Redirect to="/login" />
  )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  navbar: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  navbar: true,
};

export default PrivateRoute;
