import React, { useContext } from "react";
import DashboardCard from "../components/DashboardCard";
import PopMultipleChoiceButton from "../components/PopMultipleChoiceButton";
import MultipleChoiceClassificationDashboardContext from "../contexts/MultipleChoiceClassificationDashboardContext";

const MultipleChoiceClassificationDashboard = () => {
  const {
    totalToClassify,
    totalClassified,
    handlePopMultipleChoice,
    loadingNewMultipleChoice,
  } = useContext(MultipleChoiceClassificationDashboardContext);

  return (
    <>
      <div className="row">
        <div className="col-6">
          <DashboardCard title="Imagens Batidas" icon="check">
            {totalClassified}
          </DashboardCard>
        </div>
        <div className="col-6">
          <DashboardCard title="Imagens Restantes" icon="spinner">
            {totalToClassify}
          </DashboardCard>
        </div>
      </div>
      <div className="d-flex flex-row-reverse mt-5">
        <PopMultipleChoiceButton
          onPopMultipleChoice={handlePopMultipleChoice}
          disabled={loadingNewMultipleChoice}
        >
          Buscar nova imagem
        </PopMultipleChoiceButton>
      </div>
    </>
  );
};
export default MultipleChoiceClassificationDashboard;
