import { get } from "lodash";

const ClassificationMultipleChoiceReducer = (state, action) => {
  switch (action.type) {
    case "SET_MULTIPLE_CHOICE_CLASSIFICATION":
      return {
        ...state,
        multipleChoiceClassification: action.payload,
      };
    case "FIND_CURRENT_QUESTION":
      return { ...state, shouldFindCurrentQuestion: false };
    case "SET_CURRENT_QUESTION":
      return { ...state, currentQuestion: action.payload };
    case "SET_CLASSIFICATION_MODE":
      return {
        ...state,
        classificationMode: action.payload,
        shouldFindCurrentQuestion: true,
        currentQuestion: 0,
      };
    case "UPDATE_ANSWERS":
      return { ...state, shouldUpdateAnswers: false };
    case "SET_NEXT_BACK_QUESTIONS":
      return { ...state, ...action.payload };
    case "CHANGE_ANSWERS":
      const { currentQuestion, multipleChoiceClassification } = state;
      let newAnswers = get(multipleChoiceClassification, "result", []);
      newAnswers[currentQuestion - 1] = action.payload;
      return {
        ...state,
        shouldUpdateAnswers: true,
        multipleChoiceClassification: {
          ...multipleChoiceClassification,
          result: newAnswers,
        },
      };
    default:
      throw new Error();
  }
};

export default ClassificationMultipleChoiceReducer;
