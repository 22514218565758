import React from 'react';
import Rodal from 'rodal';


const ChangeEssayModal = ({
  visible, onClose, changeNewEssay, submitting,
}) => (
  <section className="modal-80">
    <Rodal visible={visible} onClose={onClose}>
      <div className="modalConfirmacao">
        <div className="boxTitulo">
          <h1 className="tituloConfirmacao">Alerta</h1>
        </div>
        <p className="conteudoConfirmacao">Deseja realmente mudar para esta redação ? ?</p>
        <div>
          <div className="float-right">
            <button
              type="button"
              disabled={submitting}
              className="btn btn-primary ml-2"
              onClick={changeNewEssay}
            >
              Sim
            </button>
            <button type="button" className="btn btn-secondary ml-2" onClick={onClose}>
              Não
            </button>
          </div>
        </div>
      </div>
    </Rodal>
  </section>
);


export default ChangeEssayModal;
