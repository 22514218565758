import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../consts';


const ClassificationUserStatistics = () => {
  const [{ classifieds, goal }, setState] = useState({ classified: undefined, goal: undefined });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${API_URL}/classification/statistics`);
      setState({ ...result.data });
    };
    fetchData();
    setInterval(fetchData, 600000);

    return () => clearInterval(fetchData);
  }, []);

  return (
    <div className="classification-user-statistics">
      <p>Classificadas Hoje: {classifieds}</p>
      <p>Meta: {goal}</p>
    </div>
  );
};

export default ClassificationUserStatistics;
