import React, { useContext } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import NavBarContext from '../contexts/NavBarContext';
import withUser from '../../hoc/withUser';
import ClassificationUserStatistics from './ClassificationUserStatistics';

const NavBarUser = ({ user }) => {
  const {
    togglePopover, getPopoverClass, handleLogout,
  } = useContext(NavBarContext);

  return (
    <div className="col">
      <div className="info-header">
        <ClassificationUserStatistics />
        <div className="profile-header">
          <button
            type="button"
            className="btn-profile"
            onClick={() => togglePopover('profilePopover')}
          >
            <span className="hidden-xs hidden-sm label-user">{get(user, 'last_name')}</span>
            <figure className="ml-3">
              <i className="fas fa-user" />
            </figure>
          </button>
        <div className={`profile-popover item-popover-fix ${getPopoverClass('profilePopover')}`}>
          <ul>
            <button
              onClick={handleLogout}
              type="button"
              className="btn-logout"
            >
              <li>Sair</li>
            </button>
          </ul>
        </div>
        </div>
      </div>
    </div>
  );
};

NavBarUser.propTypes = {
  user: PropTypes.shape({
    last_name: PropTypes.string,
  }).isRequired,
};

export default withUser(NavBarUser);
