import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { API_URL } from '../../../consts';

export const fetchNextEssayError = () => axios.get(`${API_URL}/batch/batch-error/next_error`).then((response) => response.data).catch((err) => {
    toast.error(get(err, 'response.data.barcode.0', 'Ocorreu um erro desconhecido!'));
    return Promise.reject(new Error());
});

export const fetchNextEssayReserveError = () => axios.get(`${API_URL}/batch/batch-error/next_reserve_error`).then((response) => response.data).catch((err) => {
    toast.error(get(err, 'response.data.barcode.0', 'Ocorreu um erro desconhecido!'));
    return Promise.reject(new Error());
});

export const updateBarcode = (id, data) => axios.post(`${API_URL}/batch/batch-error/${id}/update_barcode`, data).then((response) => response.data).catch((err) => {
    toast.error(get(err, 'response.data.barcode.0', 'Ocorreu um erro desconhecido!'));
    return Promise.reject(new Error());
});

export const updateError = (id, data) => axios.post(`${API_URL}/batch/batch-error/${id}/update_error`, data).then((response) => response.data).catch((err) => {
    toast.error(get(err, 'response.data.barcode.0', 'Ocorreu um erro desconhecido!'));
    return Promise.reject(new Error());
});

export const postScreening = (id ,data) => axios.post(`${API_URL}/batch/${id}/screening`, data)
    .then((response) => {
        toast.success(response.data.non_field[0],{autoClose: 1500, hideProgressBar: true});
    })
    .catch((err) => {
        Object.entries(err.response.data).forEach(([key, value]) => {
            toast.error(value[0]);
        });
        return Promise.reject(new Error());
    });
