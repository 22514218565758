import React from 'react';
import PropType from 'prop-types';

const Card = ({ children, isFluid }) => {
  const className = isFluid ? 'container-fluid' : '';

  const style = {
    paddingLeft: isFluid ? 0 : 100,
    paddingRight: isFluid ? 0 : 100,
  };

  return (
    <div className={className}>
      <div style={style}>
        <div className="page-container-border">
          {children}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropType.element.isRequired,
  isFluid: PropType.bool,
};

Card.defaultProps = {
  isFluid: false,
};

export default Card;
