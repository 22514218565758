import React from "react";
import AsyncSelect from "react-select/async";

const AsyncSelectInput = ({
  fetchOptions,
  defaultOptions = false,
  onChange,
  label,
  placeholder = "Selecione",
  disabled = false,
  noOptionsMessage = "Nenhum dado encontrado.",
}) => {
  return (
    <div>
      <label className="font-weight-bold form-check-label mb-1">{label}</label>
      <AsyncSelect
        loadOptions={fetchOptions}
        onChange={onChange}
        defaultOptions={defaultOptions}
        isClearable
        placeholder={placeholder}
        isDisabled={disabled}
        noOptionsMessage={({ inputValue }) =>
          inputValue === "" ? "Digite para pesquisar" : noOptionsMessage
        }
      />
    </div>
  );
};

export default AsyncSelectInput;
