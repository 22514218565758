import React, { useReducer, useEffect } from "react";
import MultipleChoiceClassificationDashboardContext, {
  initialMultipleChoiceClassificationDashboardState,
} from "../contexts/MultipleChoiceClassificationDashboardContext";
import MultipleChoiceClassificationDashboardReducer from "../reducers/MultipleChoiceClassificationDashboardReducer";
import {
  fetchMultipleChoiceClassificationDashboard,
  popMultipleChoiceClassification,
} from "../services/multiple-choice-classification";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "lodash";

const MultipleChoiceClassificationDashboardState = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(
    MultipleChoiceClassificationDashboardReducer,
    initialMultipleChoiceClassificationDashboardState
  );

  const setDashboardMetrics = async () => {
    const { data } = await fetchMultipleChoiceClassificationDashboard();
    dispatch({ type: "SET_DASHBOARD_METRICS", payload: data });
  };

  useEffect(() => {
    setDashboardMetrics();
  }, []);

  const handlePopMultipleChoice = async () => {
    dispatch({ type: "FIND_NEW_MULTIPLE_CHOICE" });
    try {
      const data = await popMultipleChoiceClassification();
      history.push(`/multiple-choice-classificiation/${data.id}`);
    } catch (err) {
      let message = "Ocorreu um erro inesperado, tente novamente";
      const status = get(err, "response.status");
      console.log(err);
      if (status === 404) {
        message = "Nenhuma imagem disponível";
      }
      toast.error(message);
    } finally {
      dispatch({ type: "FOUNDED_NEW_MULTIPLE_CHOICE" });
    }
  };

  const contextValue = {
    ...state,
    handlePopMultipleChoice,
  };

  return (
    <MultipleChoiceClassificationDashboardContext.Provider value={contextValue}>
      {children}
    </MultipleChoiceClassificationDashboardContext.Provider>
  );
};

export default MultipleChoiceClassificationDashboardState;
