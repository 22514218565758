import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Card from '../../components/Card';
import ScreeningInput from '../components/ScreeningInput';
import { postScreening, updateError, fetchNextEssayReserveError } from '../services/batch_error';
import Loading from '../../components/Loading';

class ScreeningTestPage extends React.Component {
  state = {
    image: undefined,
    loading: true,
  }

  componentDidMount = () => {
    this.fetchNextImage();
  }

  fetchNextImage = () => {
    this.setState({ loading: true });
    fetchNextEssayReserveError().then((image) => this.setState({ image: { key: image.original_key, id: image.id }, loading: false })).catch((error) => {
      const status = get(error, 'response.status');
      if (status === 404) {
        const { history } = this.props;
        history.push('/auditoria');
      }
    });
  }

  handleSkip = () => {
    this.setState({ loading: true });
    const { image } = this.state;
    updateError(image.id, {}).then(() => this.fetchNextImage()).catch(() => this.setState({ loading: false }));
  }

  handleSubmit = (value) => {
    const { barcode, enrolment_key } = value;
    const { image } = this.state;
    this.setState({ loading: true });
    postScreening(image.id, { barcode, enrolment_key }).then(() => this.fetchNextImage()).catch(() => this.setState({ loading: false }));
  }

  render() {
    const { image, loading } = this.state;

    return (
      <Card isFluid>
        <section className="validador-images">
          <div className="row">
            <Loading loading={loading}>
              <ScreeningInput image={image} onSubmit={this.handleSubmit} onClick={this.handleSkip} />
            </Loading>
          </div>
        </section>
      </Card>
    );
  }
}

ScreeningTestPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(ScreeningTestPage);
