import Axios from "axios";
import { API_URL } from "../consts";
import { getToken } from "../services";

const api = Axios.create({
  baseURL: API_URL,
  timeout: 5000,
});

api.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `JWT ${getToken()}`,
      "Content-Type": "application/json",
    },
  };
});

export default api;
