import React from "react";

export const initialClassificationMultipleChoiceState = {
  multipleChoiceClassification: undefined,
  currentQuestion: 0,
  answers: [],
  shouldUpdateAnswers: false,
  classificationMode: "review",
  shouldFindCurrentQuestion: true,
  nextQuestion: 1,
  backQuestion: 0,
  handleNextQuestion: () => undefined,
  handleBackQuestion: () => undefined,
  handleChangeAnswer: () => undefined,
  handleChangeClassificationMode: () => undefined,
  handleClassifyMultipleChoice: () => undefined,
};

const ClassificationMultipleChoiceContext = React.createContext({
  ...initialClassificationMultipleChoiceState,
});

export default ClassificationMultipleChoiceContext;
