import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Table from '../../components/Table';


const UserDuplicityTable = ({
  data, loading, fetchingProgress, location: { pathname }
}) => {
  const renderCell = (d) => {
    if (fetchingProgress) {
      return 'Carregado...';
    }
    return (
      <>
        {`${get(d.value, 'classified')} / ${get(d.value, 'total')}`}
        {get(d.value, 'classified') !== get(d.value, 'total') && (
          <i className="fas fa-exclamation-triangle text-warning ml-2" />
        )}
      </>
    );
  };

  const columns = [
    {
      Header: 'Nome',
      accessor: 'subscription.essay.batch',
      width: 230,
      className: 'text-center',
    },
    {
      Header: 'Erro',
      accessor: 'error',
      cell: renderCell,
      className: 'text-center',
    },
    {
      Header: 'Ações',
      accessor: 'id',
      className: 'text-center',
      Cell: (accessor) => (
        <Link to={`${pathname}/${accessor.value}`} >
          Escolher
        </Link>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
      defaultPageSize={10}
    />
  );
};

export default withRouter(UserDuplicityTable);
