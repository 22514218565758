import React, { useCallback, useEffect, useReducer } from "react";
import ClassificationMultipleChoiceContext, {
  initialClassificationMultipleChoiceState,
} from "../contexts/ClassificationMultipleChoiceContext";
import ClassificationMultipleChoiceReducer from "../reducers/ClassificationMultipleChoiceReducer";
import {
  classifyMultipleChoiceClassification,
  retrieveMultipleChoiceClassification,
  updateMultipleChoiceClassification,
} from "../services/multiple-choice-classification";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

const MultipleChoiceClassificationState = ({ children }) => {
  const [state, dispatch] = useReducer(
    ClassificationMultipleChoiceReducer,
    initialClassificationMultipleChoiceState
  );
  const { id } = useParams();
  const {
    currentQuestion,
    multipleChoiceClassification,
    shouldUpdateAnswers,
    classificationMode,
    shouldFindCurrentQuestion,
  } = state;
  const history = useHistory();

  const fetchMultipleChoiceClassification = useCallback(async () => {
    try {
      const multipleChoiceClassification =
        await retrieveMultipleChoiceClassification(id);
      dispatch({
        type: "SET_MULTIPLE_CHOICE_CLASSIFICATION",
        payload: multipleChoiceClassification,
      });
    } catch {
      toast.error("Ocorreu um error ao carregar a imagem.");
    }
  }, [id]);

  const findQuestionToReview = useCallback(
    (orientation) => {
      const answers = get(multipleChoiceClassification, "result") || [];
      const questionIndex = answers.findIndex((ans, index) => {
        const isPreviousQuestion = index + 1 < currentQuestion;
        const isFutureQuestion = index + 1 > currentQuestion;

        if (orientation === "next" && isFutureQuestion && ans === "N/A") {
          return true;
        }
        if (orientation === "back" && isPreviousQuestion && ans === "N/A") {
          return true;
        }

        return false;
      });

      return questionIndex + 1;
    },
    [multipleChoiceClassification, currentQuestion]
  );

  const getNextQuestion = useCallback(() => {
    const answers = get(multipleChoiceClassification, "result") || [];
    const isLastQuestion = answers.length === currentQuestion;
    if (classificationMode === "review") {
      return findQuestionToReview("next");
    }
    if (isLastQuestion) {
      return 0;
    }

    return currentQuestion + 1;
  }, [
    classificationMode,
    findQuestionToReview,
    currentQuestion,
    multipleChoiceClassification,
  ]);

  const getBackQuestion = useCallback(() => {
    if (classificationMode === "review") {
      return findQuestionToReview("back");
    }
    return currentQuestion - 1;
  }, [classificationMode, findQuestionToReview, currentQuestion]);

  const handleNextQuestion = useCallback(() => {
    dispatch({
      type: "SET_CURRENT_QUESTION",
      payload: getNextQuestion(),
    });
  }, [getNextQuestion]);

  const handleBackQuestion = useCallback(() => {
    dispatch({
      type: "SET_CURRENT_QUESTION",
      payload: getBackQuestion(),
    });
  }, [getBackQuestion]);

  const handleChangeAnswer = useCallback((option) => {
    dispatch({
      type: "CHANGE_ANSWERS",
      payload: option,
    });
  }, []);

  const handleChangeClassificationMode = (classificationMode) => {
    dispatch({
      type: "SET_CLASSIFICATION_MODE",
      payload: classificationMode,
    });
  };

  const handleClassifyMultipleChoice = () => {
    const answers = get(multipleChoiceClassification, "result") || [];
    classifyMultipleChoiceClassification(id, { result: answers });
    toast.success("Imagem classificada com sucesso.");
    history.push("/multiple-choice-classificiation");
  };

  useEffect(() => {
    if (shouldFindCurrentQuestion && multipleChoiceClassification) {
      dispatch({ type: "FIND_CURRENT_QUESTION" });
      dispatch({ type: "SET_CURRENT_QUESTION", payload: getNextQuestion() });
    }
  }, [
    shouldFindCurrentQuestion,
    multipleChoiceClassification,
    getNextQuestion,
  ]);

  useEffect(() => {
    if (multipleChoiceClassification) {
      dispatch({
        type: "SET_NEXT_BACK_QUESTIONS",
        payload: {
          nextQuestion: getNextQuestion(),
          backQuestion: getBackQuestion(),
        },
      });
    }
  }, [multipleChoiceClassification, getNextQuestion, getBackQuestion]);

  useEffect(() => {
    const answers = get(multipleChoiceClassification, "result") || [];
    if (shouldUpdateAnswers) {
      updateMultipleChoiceClassification(id, { result: answers });
      dispatch({
        type: "UPDATE_ANSWERS",
      });
    }
  }, [shouldUpdateAnswers, multipleChoiceClassification, id]);

  useEffect(() => {
    fetchMultipleChoiceClassification();
  }, [fetchMultipleChoiceClassification]);

  const contextValue = {
    ...state,
    handleNextQuestion,
    handleBackQuestion,
    handleChangeAnswer,
    handleChangeClassificationMode,
    handleClassifyMultipleChoice,
  };

  return (
    <ClassificationMultipleChoiceContext.Provider value={contextValue}>
      {children}
    </ClassificationMultipleChoiceContext.Provider>
  );
};

export default MultipleChoiceClassificationState;
