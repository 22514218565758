import React from 'react';
import { TextArea } from 'informed';
import PropTypes from 'prop-types';

class TextAreaInput extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    label: '',
    placeholder: '',
    error: '',
    validate: null,
    helpBlock: '',
  };

  render() {
    const {
 label, field, error, helpBlock, validate, disabled, placeholder,
} = this.props;

    return (
      <div>
        <label htmlFor="form-state-name">{label}</label>
        <TextArea
          field={field}
          validate={validate}
          disabled={disabled}
          placeholder={placeholder}
          className="w-100 text-area-border p-3"
          rows="8"
          style={error ? { border: 'solid 1px red' } : null}
        />
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: 'red' }}>{error}</small> : null}
      </div>
    );
  }
}

export default TextAreaInput;
