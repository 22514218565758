import React, { useEffect } from 'react';
import PropType from 'prop-types';
import ClassificationButtonGroup from './ClassificationButtonGroup';
import { addOnKeyListener } from '../../listeners/key_listener';
import withUser from '../../hoc/withUser';


const Toolbar = ({ onClassifyImages, hasPermission }) => {
  const CLASSIFICATION_MOVES = [
    {
      destination: {
        label: 'Sem Texto (S)',
        value: 'blank',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
    },
    {
      destination: {
        label: 'Texto Insuficiente (T)',
        value: 'insufficient',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
      color: '#CA4246',
    },
    {
      destination: {
        label: 'Redação (R)',
        value: 'essay',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
      color: '#3BA357',
    },
  ];

  const SCAN_MOVES = [
    {
      destination: {
        label: 'Imagem Ilegível (I)',
        value: 'reescanear_qualidade',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
    },
    {
      destination: {
        label: 'Imagem Cortada (C)',
        value: 'reescanear_cortada',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
    },
  ];

  const GET_MORE_MOVES = [
    {
      destination: {
        label: 'Mais Imagens (M)',
        value: 'get_more',
      },
      onClassifyImage: onClassifyImages,
      loading: false,
      color: '#DD7C60',
    },
  ];

  const keyEventMap = {
    s: () => onClassifyImages('blank'),
    t: () => onClassifyImages('insufficient'),
    r: () => onClassifyImages('essay'),
    i: () => onClassifyImages('reescanear_qualidade'),
    c: () => onClassifyImages('reescanear_cortada'),
    m: () => onClassifyImages('get_more'),
  };

  useEffect(() => {
    const eventFunction = addOnKeyListener('keypress', keyEventMap);
    return () => {
      document.removeEventListener('keypress', eventFunction);
    };
  }, []);

  return (
    <div className="buttons-validate mt-4">
      <ul className="toolbar-unordered-list">
        <div className="toolbar-container d-flex justify-content-between">
          <ClassificationButtonGroup
            groupName="ALTERAR PARA:"
            actions={CLASSIFICATION_MOVES}
          />
          {/* <ClassificationButtonGroup
            groupName="BUSCAR:"
            actions={GET_MORE_MOVES}
          /> */}
        </div>
        {hasPermission('ask_for_scan') && (
          <div className="toolbar-container">
            <ClassificationButtonGroup
              groupName="REDIGITALIZAR:"
              actions={SCAN_MOVES}
            />
          </div>
        )}
      </ul>
    </div>
  );
};

Toolbar.propTypes = {
  onClassifyImages: PropType.func.isRequired,
  hasPermission: PropType.func.isRequired,
};

export default withUser(Toolbar);
