import React from 'react';
import ToClassifyImageDuplicity from './ToClassifyImageDuplicity';


const ClassifyOriginalEssay = ({ images, keepCurrentEssay }) => (
  <section className="validador-images" style={{width:"200%"}}>
    <div className="row">
        <ToClassifyImageDuplicity
          image={images}
          onImageClick={keepCurrentEssay}
        />
    </div>
  </section>
);


export default ClassifyOriginalEssay;
