const MultipleChoiceClassificationDashboardReducer = (state, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_METRICS":
      const {
        total_classified: totalClassified,
        total_to_classify: totalToClassify,
      } = action.payload;

      return { ...state, totalClassified, totalToClassify };
    case "FIND_NEW_MULTIPLE_CHOICE":
      return { ...state, loadingNewMultipleChoice: true };
    case "FOUNDED_NEW_MULTIPLE_CHOICE":
      return { ...state, loadingNewMultipleChoice: false };
    default:
      throw new Error();
  }
};

export default MultipleChoiceClassificationDashboardReducer;
