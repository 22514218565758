import React, { useContext } from "react"
import ImageContainer from "./ImageContainer"
import ClassificationForm from "./ClassificationForm"
import ClassificationFullQuestionForm from "./ClassificationFullQuestionForm"
import ClassificationMultipleChoiceContext from "../contexts/ClassificationMultipleChoiceContext"
import { get } from "lodash"

const MultipleChoiceClassification = () => {
  const {
    multipleChoiceClassification,
    currentQuestion,
    nextQuestion,
    backQuestion,
    classificationMode,
    handleNextQuestion,
    handleBackQuestion,
    handleChangeAnswer,
    handleChangeClassificationMode,
    handleClassifyMultipleChoice,
  } = useContext( ClassificationMultipleChoiceContext )

  const classificationModes = [
    {
      name: "Revisão",
      active: classificationMode === "review",
      key: "review",
    },
    {
      name: "Correção",
      active: classificationMode === "fix",
      key: "fix",
    },
  ]

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <ImageContainer
            src={get( multipleChoiceClassification, "essay.original_src" )}
          />
        </div>
        <div className="col-md-6">
          <ClassificationFullQuestionForm
            answers={get( multipleChoiceClassification, "result" ) || []}
            handleChangeAnswer={handleChangeAnswer}
            onClassifyMultipleChoice={handleClassifyMultipleChoice}
          />
          {/* Not remove this commentary */}
          {/* <ClassificationForm
            currentQuestion={currentQuestion}
            nextQuestion={nextQuestion}
            backQuestion={backQuestion}
            classificationModes={classificationModes}
            onChangeClassificationMode={handleChangeClassificationMode}
            answers={get( multipleChoiceClassification, "result" ) || []}
            handleNextQuestion={handleNextQuestion}
            handleBackQuestion={handleBackQuestion}
            handleChangeAnswer={handleChangeAnswer}
            onClassifyMultipleChoice={handleClassifyMultipleChoice}
          /> */}
        </div>
      </div>
    </>
  )
}

export default MultipleChoiceClassification
