import React from "react";
import { Redirect } from "react-router-dom";
import { PrivateRoute } from "../components";
import UserBatchListPage from "./classification/pages/UserBatchListPage";
import UserBatchPage from "./classification/pages/UserBatchPage";
import BarcodeInputPage from "./manual/pages/BarcodeInputListPage";
import ScreeningTestPage from "./manual/pages/ScreeningTestPage";
import UserBatchDuplicityPage from "./classification/pages/UserBatchDuplicityPage";
import UserDuplicityResolve from "./classification/pages/UserDuplicityResolve";
import { makeRouteComponents } from "../helpers/routes";
import multipleChoiceClassificationRoutes from "./multiple-choice-classification/routes";
import MultipleChoiceClassificationState from "./multiple-choice-classification/states/MultipleChoiceClassificationState";

const Router = () => (
  <>
    <PrivateRoute
      path="/"
      exact
      component={() => <Redirect to="/classificacao" />}
    />
    <PrivateRoute path="/classificacao" exact component={UserBatchListPage} />
    <PrivateRoute path="/auditoria" exact component={UserBatchListPage} />
    <PrivateRoute path="/classificacao/:id" component={UserBatchPage} />
    <PrivateRoute path="/auditoria/:id" component={UserBatchPage} />
    <PrivateRoute path="/barcode" component={BarcodeInputPage} />
    <PrivateRoute path="/screening" component={ScreeningTestPage} />
    <PrivateRoute path="/duplicity" exact component={UserBatchDuplicityPage} />
    <PrivateRoute path="/duplicity/:id" component={UserDuplicityResolve} />

    {makeRouteComponents(multipleChoiceClassificationRoutes, {
      state: MultipleChoiceClassificationState,
    })}
  </>
);

export default Router;
