import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import * as pages from '.';
import {
  PrivateRoute,
} from '../components';
import Router from '../v2/Router';
import { isAuthenticated } from '../services';
import { API_URL } from '../consts';
import { toastConfig } from '../utils';
import Loading from '../v2/components/Loading';
import { getError } from '../services/utils';
import UserContext from '../v2/contexts/UserContext';

class Main extends Component {
  state = {
    user: {},
    permissions: [],
    mainLoading: false,
  }

  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    if (token) {
      axios.defaults.headers.common.Authorization = `JWT ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }

  componentDidMount() {
    if (isAuthenticated()) {
      axios.all([
        axios.get(`${API_URL}/permissions`),
      ])
        .then(axios.spread((
          permissionsResponse,
        ) => {
          this.setState({
            permissions: permissionsResponse.data,
            mainLoading: false,
          });
        }))
        .catch((error) => {
          const { history } = this.props;
          const { status, data } = getError(error);
          if (status === 403 || status === 401) {
              history.push('/login');
              toast.error(data.detail, toastConfig);
          } else {
            history.push('/');
            this.setState({ mainLoading: false });
          }
          if (status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('authorized');
          }
        });
    }
  }

  hasPermission = (permission) => {
    const { permissions } = this.state;

    if (permission === '' || permissions.includes('__all__')) {
      return true;
    }

    return permissions.includes(permission);
  }

  render() {
    const { user, permissions, mainLoading } = this.state;
    return (
      <UserContext.Provider value={{ user, permissions, hasPermission: this.hasPermission }}>
        <Loading loading={mainLoading}>
          <PrivateRoute exact path="/manual" component={pages.ValidacaoImagemManual} />
          <PrivateRoute path="/atribui" component={pages.AtribuiImagemBatedor} />
          <PrivateRoute path="/batimento" component={pages.ImagensBatidas} />
          <PrivateRoute path="/lotes" component={pages.AcompanhamentoLote} />
          <PrivateRoute path="/batimento-linhas" component={pages.VerificarLinhasImagens} />
          <Router />
        </Loading>
      </UserContext.Provider>
    );
  }
}

Main.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default withRouter(Main);
