import React from 'react';
import PropTypes from 'prop-types';

const NavBarContainer = ({ children }) => (
  <nav className="navbar-nv1">
    <div className="container">
      <div className="row">
        {children}
      </div>
    </div>
  </nav>
);

NavBarContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBarContainer;
