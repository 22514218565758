import React, { Fragment } from 'react';
import { RadioGroup, Radio } from 'informed';
import PropTypes from 'prop-types';

const getInputId = item => `${item.field}-${item.value}`;

class RadioInput extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpBlock: PropTypes.string,
    validate: PropTypes.func,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
  }

  static defaultProps = {
    disabled: false,
    options: [],
    error: '',
    helpBlock: '',
    validate: null,
  }

  render() {
    const {
      field, error, helpBlock, validate, disabled, options,
    } = this.props;

    return (
      <div>
        <RadioGroup field={field} validate={validate}>
          {options.map(item => (
            <Fragment key={item.value}>
              <Radio
                value={item.value}
                id={getInputId(item)}
                disabled={disabled}
              />
              <label htmlFor={getInputId(item)} className="ml-2">{item.label}</label>
              <br />
            </Fragment>
          ))}
        </RadioGroup>
        {helpBlock ? <small>{helpBlock}</small> : null}
        {error ? <small style={{ color: 'red' }}>{error}</small> : null}
      </div>
    );
  }
}

export default RadioInput;
