/**
 * Create a Key Event Listener and mapping multiples keys and functions
 *
 * @param {string} eventType - Type of HTML API Event
 * @param {object} mapKeyEvent - Object formated by key: handleFunction
 *
 * @example
 *  const mapKeyEvent = {
 *    a: () => console.log('a press'),
 *    b: () => 2 + 3,
 *    c: () => {}
 *  }
 *  addOnKeyListener('keydown', mapKeyEvent)
 */
export const addOnKeyListener = (eventName, mapKeyEvent) => {
  const eventFunction = event => Object.keys(mapKeyEvent).forEach(key => {
    const keyFunction = mapKeyEvent[key];
    if (event.key === key) {
      keyFunction();
    }
  });
  document.addEventListener(eventName, eventFunction);
  return eventFunction;
}
