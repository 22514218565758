import React from 'react';
import Select from 'react-select';
import PropType from 'prop-types';

const ChangeProcessResult = ({ onChangeProcessResult, processResult }) => {
  const options = [
    {
      label: 'Sem Texto',
      value: 'blank',
    },
    {
      label: 'Redação',
      value: 'essay',
    },
    {
      label: 'Review',
      value: 'review',
    },
    {
      label: 'Manual',
      value: 'manual',
    },
  ];

  return (
    <div className="col-3 select-process">
      <span>Situação</span>
      {(
        <Select
          value={processResult}
          onChange={onChangeProcessResult}
          options={options}
          placeholder="Selecione uma situação"
        />
      )}
    </div>
  );
};

ChangeProcessResult.propTypes = {
  onChangeProcessResult: PropType.func.isRequired,
  processResult: PropType.shape({
    label: PropType.string,
    value: PropType.string,
  }).isRequired,
};

export default ChangeProcessResult;
