import React from 'react';

class Footer extends React.Component {
  render() {
    return (
        <footer>
          <div className="container">
            <div className="row">
              <div className="col">
              <span>Copyright © 2018 </span>
              </div>
              <div className="col">
              <span>
                <span className="mr-2">
                  <i className="fa fa-envelope" aria-hidden="true" />
                </span>
                suporte.enemredacoes@fgv.br
              </span>
              </div>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;
