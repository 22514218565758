import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';


const withUser = (Component) => {
  const WrappedComponent = ({ ...props }) => {
    const { user, permissions, hasPermission } = useContext(UserContext);
    return (
      <Component
        user={user}
        permissions={permissions}
        hasPermission={hasPermission}
        {...props}
      />
    );
  };

  return WrappedComponent;
};


export default withUser;
