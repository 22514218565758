import React from 'react';
import { get } from 'lodash';

class ComponentBase extends React.Component {
  hasPermission(permission) {
    const permissions = get(this.props, 'user.user.permissions', {});
    return permissions[permission] === true;
  }

  hasFeature(feature) {
    const features = get(this.props, 'user.features', []);
    return features.find(item => item.codigo === feature) !== undefined;
  }
}

export default ComponentBase;
