import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavBarContainer from './NavBarContainer';
import NavBarLogo from './NavBarLogo';
import NavBarUser from './NavBarUser';
import NavBarContext from '../contexts/NavBarContext';
import { logout } from '../../../services';
import NavBarMenu from './NavBarMenu';

class NavBar extends React.Component {
  state = {};

  getPopoverClass = (popover) => {
    const { [popover]: isOpen } = this.state;
    return isOpen ? 'show' : 'hidden';
  }

  togglePopover = (popover) => {
    const { [popover]: isOpen } = this.state;
    this.setState({ [popover]: !isOpen });
    document.body.addEventListener('mouseup', (e) => {
      if (e.target.className !== null && e.target.className.indexOf('item-popover-fix') === -1) {
        this.setState(
          { [popover]: false },
        );
      }
    }, true);
  }

  handleLogout = () => {
    const { history } = this.props;
    logout();
    history.push('/login');
  }

  render() {
    const value = {
      togglePopover: this.togglePopover,
      getPopoverClass: this.getPopoverClass,
      handleLogout: this.handleLogout,
    };

    return (
      <NavBarContext.Provider value={value}>
        <NavBarContainer>
          <NavBarLogo />
          <NavBarUser />
        </NavBarContainer>
        <NavBarMenu />
      </NavBarContext.Provider>
    );
  }
}

NavBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(NavBar);
