import React from 'react';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import menuItens from '../menu';
import withUser from '../../hoc/withUser';

const NavBarMenu = ({ hasPermission }) => (
  <nav className="navbar-nv2 hidden-xs hidden-sm">
    <div className="container pt-0 pb-0">
      <ul className="navbar-corretor">
        {menuItens.filter((menuItem) => hasPermission(menuItem.permission)).map((menuItem) => (
          <Link key={shortid.generate()} to={menuItem.link}>
            <li className="navbar-item">{menuItem.name}</li>
          </Link>
        ))}
      </ul>
    </div>
  </nav>
);

NavBarMenu.propTypes = {
  hasPermission: PropTypes.func.isRequired,
};

export default withUser(NavBarMenu);
