import axios from 'axios';
import { API_URL } from '../consts';

export const getToken = () => localStorage.getItem('token');

export const setUnauthorizedToken = (token) => {
  localStorage.setItem('token', token);
};

export const setAuthorizedToken = (token) => {
  localStorage.setItem('token', token);
};

export const isAuthenticated = () => getToken() !== null;

export const getAxiosConfig = () => ({
  headers: {
    Authorization: `JWT ${getToken()}`,
    'Content-Type': 'application/json',
  },
});

export const login = credentials => axios.post(`${API_URL}/login`, credentials);

export const logout = () => {
  localStorage.removeItem('token');
};
