import React from 'react';
import PropType from 'prop-types';
import PaginationInfo from './PaginationInfo';
import ChangeProcessResult from './ChangeProcessResult';

const UserBatchInfo = ({
  userBatch, onChangeProcessResult, processResult,
}) => (
  <>
    <h1 className="titulo-tabela-batedor">{userBatch.batch_id}</h1>
    <div className="row">
      <ChangeProcessResult
        onChangeProcessResult={onChangeProcessResult}
        processResult={processResult}
      />
      <PaginationInfo {...userBatch} />
    </div>
  </>
);

UserBatchInfo.propTypes = {
  userBatch: PropType.shape({
    batch_id: PropType.string,
  }),
  onChangeProcessResult: PropType.func.isRequired,
  processResult: PropType.shape({
    label: PropType.string,
    value: PropType.string,
  }).isRequired,
};

UserBatchInfo.defaultProps = {
  userBatch: {},
};

export default UserBatchInfo;
