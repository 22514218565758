import React, { useCallback, useReducer } from "react"
import { get } from "lodash"
import { initialClassificationMultipleChoiceState } from "../contexts/ClassificationMultipleChoiceContext"
import ClassificationMultipleChoiceReducer from "../reducers/ClassificationMultipleChoiceReducer"
import ClassifyMultipleChoiceButton from "./ClassifyMultipleChoiceButton"

const ClassificationFullQuestionForm = ( {
  answers,
  onClassifyMultipleChoice
} ) => {

  const [state, dispatch] = useReducer(
    ClassificationMultipleChoiceReducer,
    initialClassificationMultipleChoiceState
  )

  const handleAnswerChange = ( option, currentAnsw ) => {
    let newAnswers = answers
    newAnswers[currentAnsw] = option
    dispatch( { type: "CHANGE_ANSWERS", payload: option } )
  }
  const questionOptions = ["A", "B", "C", "D", "E", "N/A"]

  const isChecked = ( option, currentAnsw ) => {
    const currentAnswer = get( answers, currentAnsw )
    return option === currentAnswer
  }

  const renderQuestion = ( currentAns ) => {
    return (
      <>
        <legend style={{ fontWeight: 'bold' }} class="col-form-label">Questão {currentAns + 1}</legend>
        {
          questionOptions.map( ( option ) => (
            <>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name={`questionAnswer${currentAns}`}
                  id={`questionAnswer${currentAns}`}
                  value={option}
                  checked={isChecked( option, currentAns )}
                  onClick={() => handleAnswerChange( option, currentAns )}
                  style={{ marginLeft: '0.5rem', width: '25px', height: '25px' }}
                />
                <label
                  class="form-check-label"
                  for={`inlineRadio${currentAns}`}
                >
                  {option}
                </label>
              </div>
            </>
          ) )
        }
      </>
    )
  }

  return (
    <div style={{ width: "auto", marginLeft: "15px" }}>
      <div className="row">
        <div className="col-6">
          {answers.map( ( ans, currentAns ) => currentAns < 16 && (
            <div style={{ background: '#f5f5f5', border: '1px solid #bfbfbf', marginTop: '0.7rem', padding: '0.9rem', borderRadius: '8px', width: 'auto' }}>
              {renderQuestion( currentAns )}
            </div>

          ) )}
        </div>
        <div className="col-6">
          {answers.map( ( ans, currentAns ) => currentAns >= 16 && (
            <div style={{ background: '#f5f5f5', border: '1px solid #bfbfbf', marginTop: '0.7rem', padding: '0.9rem', borderRadius: '8px', width: 'auto' }}>
              {renderQuestion( currentAns )}
            </div>
          ) )}
        </div>
      </div>
      <br />
      <ClassifyMultipleChoiceButton
        onClassifyMultipleChoice={onClassifyMultipleChoice}
      >
        Enviar
      </ClassifyMultipleChoiceButton>
    </div>
  )
}

export default ClassificationFullQuestionForm
