import React, { Fragment } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactCrop from 'react-image-crop';
import { Footer, Loading } from '../components';
import { getAxiosConfig } from '../services';
import { API_URL } from '../consts';
import 'react-image-crop/dist/ReactCrop.css';

class ValidacaoImagemManual extends React.Component {
  constructor(props) {
    super(props);
    this.fetchImages = this.fetchImages.bind(this);

    this.state = {
      images: [],
      total_images: 0,
      total_pages: 0,
      page: 1,
      loadingFetchImages: false,
      imageSelect: null,
      crop: {
        x: 3,
        y: 18,
        width: 92,
        height: 75,
      },
      croppedImageUrl: null,
    };
  }

  componentDidMount() {
    this.fetchImages();
    window.addEventListener('keydown', (e) => {
      if (e.code === 'KeyC') {
        this.setCoordenadas();
      } else if (e.code === 'KeyR') {
        this.redigitalizarImg();
      } else if (e.code === 'keyM') {
        this.fetchImages();
      }
    });
  }

  onImageLoaded = async (image, pixelCrop) => {
    this.imageRef = image.src;
    this.getCroppedImg(
      this.imageRef,
      pixelCrop,
      'imgCropped.tiff',
    );
  };

  onChange = (crop) => {
    this.setState({ crop });
  }

  onComplete = (crop, pixelCrop) => {
    this.getCroppedImg(
      this.imageRef,
      pixelCrop,
      'imgCropped.tiff',
    );
    this.setState({ pixelCrop });
  };

  setCoordenadas = () => {
    const { images_id, pixelCrop, images_processamento_id, loadingFetchImages } = this.state;
    if (!loadingFetchImages) {
      if (images_id && pixelCrop && images_processamento_id) {
        this.setState({ loadingFetchImages: true });
        toast.info('Enviando', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500 });
        axios.post(`${API_URL}/batimento/usuario_imagens_cropped`, {
          images_id,
          images_processamento_id,
          coordenadas: pixelCrop,
        }, getAxiosConfig()).then(() => {
          this.setState({ loadingFetchImages: false });
          this.fetchImages();
          toast.success('Imagem enviada para recorte', { position: toast.POSITION.BOTTOM_CENTER });
        }).catch(() => {
          toast.error('Ocorreu um erro ao enviar para recorte', { position: toast.POSITION.BOTTOM_CENTER });
        });
      } else {
        toast.error('Selecione uma imagem para fazer o recorte', { position: toast.POSITION.BOTTOM_CENTER });
      }
    }
  }

  getCroppedImg = (img, pixelCrop) => {
    const canvas = document.getElementById('imgCropped');

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    if (canvas.getContext) {
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
      };
      image.src = img;
    }
  }

  redigitalizarImg = () => {
    const { images_id, images_processamento_id } = this.state;
    if (images_id && images_processamento_id) {
      toast.info('Enviando', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500 });
      axios.post(`${API_URL}/batimento/move`, {
        images_id,
        destination: 'reescanear_cortada',
        images_processamento_id,
      }, getAxiosConfig()).then(() => {
        this.fetchImages();
        toast.success('Imagem enviada para redigitalização', { position: toast.POSITION.BOTTOM_CENTER });
      }).catch(() => {
        toast.error('Ocorreu um erro ao enviar para redigitalizar', { position: toast.POSITION.BOTTOM_CENTER });
      });
    } else {
      toast.error('Selecione uma imagem para redigitalizar', { position: toast.POSITION.BOTTOM_CENTER });
    }
  }

  checkImage(idImage, url) {
    const { images } = this.state;
    const index = images.find(element => element.processamento.id === idImage);
    const { id } = index;
    this.setState({
      images_id: id,
      imageSelect: url,
      images_processamento_id: index.processamento.id,
    });
  }

  fetchImages() {
    const { filters, page } = this.state;
    this.setState({ loadingFetchImages: true, imageSelect: null });
    const { headers } = getAxiosConfig();
    axios.get(`${API_URL}/batimento/usuario_imagens_manual`,
    {
      params: { page, filters }, headers,
    }).then((response) => {
      this.setState({ loadingFetchImages: false });
      const images = [];
      for (let i = 0; i < response.data.results.length; i += 1) {
        images.push(response.data.results[i]);
      }
      const total_images = response.data.count;
      const { total_pages } = response.data;
      this.setState({
        images,
        total_images,
        total_pages,
        loadingFetchImages: false,
        imageSelect: images[0].public_url,
        images_id: images[0].id,
        images_processamento_id: images[0].processamento.id,
      });
    }).catch(() => {
      console.log('err');
    });
  }

  renderImgs() {
    const { images, loadingFetchImages } = this.state;
    if (loadingFetchImages) {
      return '';
    }
    return images.map(image => (
        <div key={image.processamento.id}>
          <button
            type="button"
            tabIndex={0}
            onClick={() => this.checkImage(image.processamento.id, image.public_url)}
            className={`lista-img-validar ${image.check ? 'active' : ''}`}
          >
            <figure>
              <img src={image.public_url} id="image-authorize" className="img-responsive" alt="Imagem da prova" />
            </figure>
            <figcaption>
              <div className="col-12">
                <article className="text-left">
                  <span className="validador-txt-destaque">
                    {image.processamento.co_inscricao}
                  </span>
                </article>
              </div>
              <div className="col-12">
                <article className="text-left mt-2">
                  <span className="validador-txt-destaque-silver">
                    Lote:
                  </span>
                  <span>
                    {image.processamento.batch}
                  </span>
                </article>
              </div>
            </figcaption>
          </button>
        </div>
    ));
  }

  render() {
    const {
      page,
      total_images,
      total_pages,
      imageSelect,
      crop,
      croppedImageUrl,
      loadingFetchImages,
    } = this.state;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="page-container-border">
            <section className="section-search">
              <div className="row">
                <div className="col-12 pt-3 mt-1 mb-3 w-100">
                  <div className="offset-9 info-pagination p-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                      total_pages === 0 && (
                        <span>
                          Página 0 de {total_pages}
                        </span>
                      )
                    }
                    {
                      total_pages !== 0 && (
                        <span>
                          Página {page} de {total_pages}
                        </span>
                      )
                    }
                    <span className="ml-3">
                      Total de imagens: {total_images}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="image-section">
                    <div className="buttons-validate">
                      <ul className="validador-lista-actions">
                        <li>
                          <button onClick={this.redigitalizarImg} type="submit" className="btn btn-primary ml-2 mt-2">
                            REDIGITALIZAR (R)
                          </button>
                        </li>
                        <li>
                          <button onClick={this.setCoordenadas} type="submit" className="btn btn-terciary ml-2 mt-2">
                            CORTAR (C)
                          </button>
                        </li>
                      </ul>
                    </div>
                    <section className="validador-images">
                      <img src={croppedImageUrl} alt="" />
                      <div className="row">
                        <div className="col-2">
                          <div className="row">
                            <div className="overflow" style={{ maxHeight: 750, overflowY: 'auto', overflowX: 'hidden' }}>
                              {
                                this.renderImgs()
                              }
                            </div>
                          </div>
                        </div>
                        {
                          imageSelect ? (
                            <Fragment>
                              <div className="col-4">
                                <ReactCrop
                                  src={imageSelect}
                                  crop={crop}
                                  onChange={this.onChange}
                                  onImageLoaded={this.onImageLoaded}
                                  onComplete={this.onComplete}
                                />
                              </div>
                              <div className="col-6">
                                <canvas id="imgCropped" style={{ border: '2px solid #333', maxWidth: '100%' }} />
                              </div>
                            </Fragment>
                          ) : (
                              <div className="col-10 text-center no-select-img-text">
                                {
                                  loadingFetchImages && <Loading />
                                }
                              </div>
                          )
                        }
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default ValidacaoImagemManual;
