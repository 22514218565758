import React, { useState, useEffect } from "react";
import { API_URL } from '../../../consts';
import { get } from "lodash";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import axios from 'axios';
import Loading from "../../components/Loading";
import ClassifyOriginalEssay from "../components/ClassifyOriginalEssay";
import ClassifyReserveEssay from "../components/ClassifyReserveEssay";
import TextInput from "../../../components/TextInput"
import KeepEssayModal from "../components/KeepEssayModal";
import ChangeEssayModal from "../components/ChangeEssayModal";
import { useHistory } from "react-router-dom";


const UserDuplicityResolve = () => {


  let history = useHistory();
  const [ data, setData ] = useState()
  const [ justification, setJustification ] = useState("")
  const [ keepModal, setKeepModal ] = useState(false)
  const [ changeModal, setChangeModal ] = useState(false)

  // @ts-ignore
  const {id} = useParams()

  function fetchBatchData() {
    axios.get(`${API_URL}/batch/duplicity/${id}`)
    .then(response => setData(response.data))
    .catch()
  }

  const handleKeepModal = () => {
    setKeepModal(!keepModal)
  }

  const handleChangeModal = () => {
    setChangeModal(!changeModal)
  }

  const keepCurrentEssay = () => {
    handleKeepModal()
    axios.patch(`${API_URL}/batch/duplicity/${id}/keep_essay` , {justification: justification})
    .then(() =>  toast.success("Redação mantida com sucesso !") && history.push("/duplicity"))
    .catch(() => toast.error("Houve algum erro"))
  }

  const changeNewEssay = () => {
    handleChangeModal()
    axios.patch(`${API_URL}/batch/duplicity/${id}/change_essay` , {justification: justification})
    .then(() =>  toast.success("Redação trocada com sucesso !") && history.push("/duplicity"))
    .catch(() => toast.error("Houve algum erro"))
  }

  useEffect(() => {
    fetchBatchData();
  },[])


    return (
      <>
        <div className="page-container-border">
          <div>
              <h1 className="titulo-tabela-batedor"> Justificativa </h1>
            <div style={{padding:"30px"}}>
              <TextInput 
                onChange={(e) => setJustification(e.target.value)}
              />
            </div>
          <div style={{display:"flex"}}>
            <div>
              <h1 className="titulo-tabela-batedor"> Folha Original </h1>
              <div className="col-12">
                  <article className="text-left">
                    <span className="validador-txt-destaque">
                        {data ?
                        <ClassifyOriginalEssay
                          images={get(data, "subscription.essay")}
                          keepCurrentEssay={handleKeepModal}
                        />
                        :
                        <Loading />
                        }
                    </span>
                  </article>
              </div>
            </div>
         
          <div>
            <h1 className="titulo-tabela-batedor"> Folha Reserva </h1>
            <div className="col-12">
              <article className="text-left">
                <span className="validador-txt-destaque">
                  {data ?
                  <ClassifyReserveEssay
                    images={get(data, "essay")}
                    changeNewEssay={handleChangeModal}
                  />
                  :
                  <Loading />
                  }
                </span>
              </article>
            </div>
          </div>  
        </div>
     </div>
     </div>

        <KeepEssayModal
          visible={keepModal}
          onClose={handleKeepModal}
          keepCurrentEssay={keepCurrentEssay}
        />
        <ChangeEssayModal 
          visible={changeModal}
          onClose={handleChangeModal}
          changeNewEssay={changeNewEssay}
        />
      </>
    )
}
export default UserDuplicityResolve