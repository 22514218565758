import React from 'react';
import PropTypes from 'prop-types';
import UserBatchTable from '../components/UserBatchTable';
import { fetchUserBatches, fetchUserBatchesProgress } from '../services/user_batch_service';
import { USER_BATCH_TYPE } from '../../../consts';

class UserBatchListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      fetchingProgress: true,
    };
  }

  updateData = (data, progress) => (
    data.map((batch) => ({
      ...progress.find((p) => p.id === batch.id),
      ...batch,
    }))
  )

  componentDidMount = () => {
    const { location: { pathname } } = this.props;

    fetchUserBatches({ type: pathname === '/auditoria' ? USER_BATCH_TYPE.AUDIT : USER_BATCH_TYPE.CLASSIFICATION })
      .then((data) => this.setState({ data, loading: false }));

    fetchUserBatchesProgress()
      .then((progress) => this.setState((prevState) => (
        { data: this.updateData(prevState.data, progress), fetchingProgress: false }
      )));
  }

  render() {
    const { data, loading, fetchingProgress } = this.state;
    return (
      <div style={{ paddingLeft: 100, paddingRight: 100 }}>
        <div className="page-container-border">
          <h1 className="titulo-tabela-batedor">Lotes atribuidos a mim</h1>
          <UserBatchTable data={data} loading={loading} fetchingProgress={fetchingProgress} />
        </div>
      </div>
    );
  }
}

UserBatchListPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserBatchListPage;
