import React from "react";
import AsyncSelectInput from "../../components/inputs/AsyncInput";
import { fetchProjects } from "../services/project";

const AsyncProjectInput = ({ onChange, defaultOptions = true }) => {
  const fetchProjectOptions = async (inputValue) => {
    const projects = await fetchProjects({ name: inputValue });
    return projects.map((p) => ({ label: p.name, value: p.id }));
  };

  return (
    <AsyncSelectInput
      label="Projeto"
      defaultOptions={defaultOptions}
      fetchOptions={fetchProjectOptions}
      onChange={onChange}
      noOptionsMessage="Nenhum projeto encontrado."
    />
  );
};

export default AsyncProjectInput;
