import React from 'react';
import { BasicText, asField } from 'informed';

export default asField(({
  fieldState, field, label, helpBlock, ...props
}) => (
  <div>
    <label htmlFor={field}>{label}</label>
    <BasicText
      className={`form-control ${fieldState.error ? 'has-error' : null}`}
      id={field}
      fieldState={fieldState}
      {...props}
    />
    {helpBlock && <small className="form-text text-muted">{helpBlock}</small>}
    {fieldState.error && <small className={fieldState.error ? 'has-error' : null}>{fieldState.error}</small>}
  </div>
));
