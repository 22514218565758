import React from 'react';
import PropType from 'prop-types';
import ToClassifyImage from './ToClassifyImage';


const ListToClassifyImages = ({ images, onCheckImage, onChangeCrop }) => (
  <section className="validador-images">
    <div className="row">
      {images.map(image => (
        <ToClassifyImage
          key={image.id}
          image={image}
          onCheckImage={onCheckImage}
          onChangeCrop={onChangeCrop}
        />
      ))}
    </div>
  </section>
);

ListToClassifyImages.propTypes = {
  images: PropType.arrayOf(PropType.shape({
    id: PropType.number,
    check: PropType.bool,
    public_url: PropType.string,
    enrolment_key: PropType.string,
    batch_name: PropType.string,
    process_result: PropType.string,
  })),
  onCheckImage: PropType.func.isRequired,
  onChangeCrop: PropType.func.isRequired,
};

ListToClassifyImages.defaultProps = {
  images: [],
};

export default ListToClassifyImages;
