import React from "react";
import AsyncSelectInput from "../../components/inputs/AsyncInput";
import { fetchSubscriptions } from "../services/subscriptions";

const AsyncSubscriptionInput = ({
  onChange,
  fetchParams = {},
  disabled = false,
}) => {
  const fetchSubscriptionOptions = async (inputValue) => {
    const subscriptions = await fetchSubscriptions({
      page_size: 100,
      name: inputValue,
      ...fetchParams,
    });
    return subscriptions.results.map((p) => ({ label: p.name, value: p.id }));
  };

  return (
    <AsyncSelectInput
      label="Candidato"
      fetchOptions={fetchSubscriptionOptions}
      onChange={onChange}
      disabled={disabled}
      noOptionsMessage="Nenhum candidato encontrado."
    />
  );
};

export default AsyncSubscriptionInput;
