import React from 'react';

const UserContext = React.createContext({
  user: {},
  permissions: [],
  features: [],
  hasPermission: () => false,
});

export default UserContext;
