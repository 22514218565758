const menuItens = [
  {
    permission: "can_view_menu_classification",
    name: "Classificação",
    link: "/classificacao",
  },
  {
    permission: "can_view_menu_audit",
    name: "Auditoria",
    link: "/auditoria",
  },
  {
    permission: "can_view_menu_barcode",
    name: "Código de barras",
    link: "/barcode",
  },
  // {
  //   permission: 'can_view_menu_line_count',
  //   name: 'Contagem de linhas',
  //   link: '/batimento-linhas',
  // },
  {
    permission: "",
    name: "Batimento questões fechadas",
    link: "/multiple-choice-classificiation",
  },
  {
    permission: "can_view_menu_screening",
    name: "Triagem de provas",
    link: "/screening",
  },
  {
    permission: "can_view_menu_screening",
    name: "Duplicidade de Redações",
    link: "/duplicity",
  },
];

export default menuItens;
