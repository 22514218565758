import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import axios from 'axios';
import PropTypes from 'prop-types';
import { API_URL } from '../../../consts';
import withUser from '../../hoc/withUser';


const ClassificationHistory = ({ image, hasPermission }) => {
  const resultTranslation = {
    blank: ' Sem texto',
    essay: ' Redação',
    insufficient: ' Insuficiente',
    review: ' Review',
    reescanear: ' Reescanear',
    reescanear_qualidade: ' Reescanear',
    reescanear_cortada: ' Reescanear',
    manual: ' Manual',
  };

  const [{ previousClassifications }, setState] = useState({ previousClassifications: [], loading: true });
  const canViewClassificationHistory = hasPermission('can_view_classification_history');

  useEffect(() => {
    if (canViewClassificationHistory) {
      const fetchData = async () => {
        const result = await axios.get(
          `${API_URL}/classification/batches/${image.user_batch_id}/to_classify/${image.id}/history`,
        );
        setState({ loading: false, previousClassifications: result.data });
      };
      fetchData();
    }
  }, []);
  if (canViewClassificationHistory) {
    return (
      <div className="col-12">
        <article className="text-left mt-2">
          <span className="validador-txt-destaque-silver">Histórico:</span>
          <div>
            {previousClassifications.map((p) => (
              <p className="history-user">{get(p, 'user')}: {resultTranslation[p.result]}</p>
            ))}
          </div>
        </article>
      </div>
    );
  }

  return null;
};

ClassificationHistory.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number,
    user_batch_id: PropTypes.string,
  }).isRequired,
  hasPermission: PropTypes.func.isRequired,
};

export default withUser(ClassificationHistory);
