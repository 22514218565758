import React from "react";

const ClassifyMultipleChoiceButton = ({
  onClassifyMultipleChoice,
  children,
  disabled,
}) => (
  <button
    type="button"
    className="btn btn-primary"
    onClick={() => onClassifyMultipleChoice()}
    disabled={disabled}
  >
    {children}
  </button>
);

export default ClassifyMultipleChoiceButton;
