import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import PropType from 'prop-types';
import { API_URL } from '../../../consts';
import { TextInput } from '../../../components';


const InputImage = ({ image, field, type, label }) => {
  const [{ key, loading }, setState] = useState({ key: '', loading: true });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.post(
        `${API_URL}/batch/image`,
        { key: image.key },
      );
      setState({ loading: false, ...result.data });
    };
    fetchData();
  }, []);

  const renderImage = () => (
    <figure>
      <img
        src={key}
        width="100%"
        id="image-authorize"
        className="img-responsive"
        alt="Imagem da prova"
      />
    </figure>
  );

  return (
    <div key={image.id} className="col-xs-12 col-sm-1 col-md-12 mb-3">
      <div className="lista-img-validar w-100">
        {loading ? (
          <figure className="w-100" style={{ height: '200px' }}>
            <Skeleton height="100%" />
          </figure>
        ) : renderImage()}
        <figcaption>
          <TextInput
            required
            type={type}
            field={field}
            label={label}
            min="0"
            max="30"
          />
        </figcaption>
      </div>
    </div>
  );
};

InputImage.propTypes = {
  image: PropType.shape({
    id: PropType.number,
    check: PropType.bool,
    key: PropType.string,
    enrolment_key: PropType.string,
    batch_name: PropType.string,
    process_result: PropType.string,
  }).isRequired,
  field: PropType.string.isRequired,
  type: PropType.string.isRequired,
  label: PropType.string.isRequired,
};

export default InputImage;
