import React from 'react';
import PropType from 'prop-types';
import ClassificationButton from './ClassificationButton';

const ClassificationButtonGroup = ({ groupName, actions, style }) => (
  <div className="toolbar-container">
    <li style={style}>
      <span className="mt-2 text-right">{groupName}</span>
    </li>
    {
      actions.map((action) => (
        <ClassificationButton
          key={action.destination.value}
          destination={action.destination}
          onClassifyImage={action.onClassifyImage}
          loadingClassifyImages={action.loading}
          className={action.className}
          color={action.color}
        />
      ))
    }
  </div>
);

ClassificationButtonGroup.propTypes = {
  groupName: PropType.string.isRequired,
  actions: PropType.arrayOf(PropType.shape({
    destination: PropType.shape({
      label: PropType.string,
      value: PropType.string,
    }),
    onClassifyImage: PropType.func,
    loading: PropType.bool,
    className: PropType.className,
  })).isRequired,
  style: PropType.shape({
    paddingLeft: PropType.string,
  }),
};

ClassificationButtonGroup.defaultProps = {
  style: {},
};

export default ClassificationButtonGroup;
