import React, { useEffect, useState } from 'react';
import { fetchDuplicityEssay } from '../services/user_batch_service';
import UserDuplicityTable from '../components/UserDuplicityTable';


const UserBatchDuplicityPage = ({}) => {

  const [batches, setBatches] = useState([])
  const [loading, setLoading] = useState(false)
  const [fetchingProgress, setFetchingProgress] = useState(false)


  const fetchData = () => {
    fetchDuplicityEssay()
    .then((data) => setBatches(data));
  }

 
  useEffect(() => {
    fetchData()
  },[])
  

    return (
      <div>
          <div className="page-container-border">
            <h1 className="titulo-tabela-batedor"> Duplicidade de Redação </h1>
            <UserDuplicityTable
              data={batches} 
              loading={loading} 
              fetchingProgress={fetchingProgress}
            />
          </div>
        </div>
    )
}

export default UserBatchDuplicityPage;