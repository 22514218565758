export const API_URL = process.env.REACT_APP_API_HOST || 'http://localhost:8000';

export const REESCANEAR_MOVE = {
  SITUACAO: [
    {
      label: 'Imagem Ilegível (I)',
      value: 'reescanear_qualidade',
    },
    {
      label: 'Imagem Cortada (C)',
      value: 'reescanear_cortada',
    },
  ],
};

export const BATIMENTO_MOVE = {
  SITUACAO: [
    {
      label: 'Sem Texto (S)',
      value: 'blank',
    },
    {
      label: 'Texto Insuficiente (T)',
      value: 'insufficient',
    },
    {
      label: 'Redação (R)',
      value: 'essay',
    },
  ],
};


export const BATIMENTO = {
  SITUACAO: [
    {
      label: 'Sem Texto',
      value: 'blank',
    },
    {
      label: 'Redação',
      value: 'essay',
    },
    {
      label: 'Manual',
      value: 'manual',
    },
    {
      label: 'Review',
      value: 'review',
    },
  ],
};

export const ATRIBUI = {
  SITUACAO: [
    {
      label: 'Sem Texto',
      value: 'blank',
    },
    {
      label: 'Redação',
      value: 'essay',
    },
    {
      label: 'Manual',
      value: 'manual',
    },
    {
      label: 'Review',
      value: 'review',
    },
  ],
};

export const VALIDACAO_FILTER = {
  SITUACAO: [
    {
      label: 'Sem Texto',
      value: 'blank',
    },
    {
      label: 'Redação',
      value: 'essay',
    },
    {
      label: 'Review',
      value: 'review',
    },
  ],
};

export const BATIMENTO_FILTER = {
  SITUACAO: [
    {
      label: 'Sem Texto',
      value: 'blank',
    },
    {
      label: 'Texto Insuficiente',
      value: 'insufficient',
    },
    {
      label: 'Redação',
      value: 'essay',
    },
    {
      label: 'Imagem Ilegível',
      value: 'reescanear_qualidade',
    },
    {
      label: 'Imagem Cortada',
      value: 'reescanear_cortada',
    },
  ],
};

export const LOTES_STATUS = {
  EM_TRANSFERENCIA: 1,
  DISPONIVEL_PROCESSAMENTO: 2,
  AGUARDANDO_PROCESSAMENTO: 3,
  EM_PROCESSAMENTO: 4,
  AGUARDANDO_BATIMENTO: 5,
  PROCESSADO: 6,
  DISPONIVEL_CORRECAO: 7,
};

export const USER_BATCH_TYPE = {
  CLASSIFICATION: 1,
  AUDIT: 2,
};
