import React, { Fragment } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Form } from 'informed';
import { Footer, SelectInput, Loading } from '../components';
import { getAxiosConfig } from '../services';
import { handleRequestError } from '../services/utils';
import {
  API_URL,
  BATIMENTO_FILTER,
  BATIMENTO_MOVE,
  REESCANEAR_MOVE,
} from '../consts';

class ImagensBatidas extends React.Component {
  constructor(props) {
    super(props);
    this.fetchImages = this.fetchImages.bind(this);
    this.filterImages = this.filterImages.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.backPage = this.backPage.bind(this);
    this.changeResultName = this.changeResultName.bind(this);
    this.moveImages = this.moveImages.bind(this);
    this.state = {
      images: [],
      page: 1,
      total_images: 0,
      total_pages: 0,
      formState: {},
      filters: {},
      filterFormState: {},
      toggle: false,
      loadingMoveImages: false,
      loadingFetchImages: false,
      next: null,
      previous: null,
    };
  }

  componentDidMount() {
    this.stateForm();
    this.fetchImages();
    document.addEventListener('keydown', this.setAtalhoKeyDown);
    document.addEventListener('keyup', this.setAtalhoKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.setAtalhoKeyDown);
    document.removeEventListener('keyup', this.setAtalhoKeyUp);
  }

  setAtalhoKeyDown = (event) => {
    if (event.shiftKey) {
      this.setState({
        shiftKey: true,
      });
    }
    if (event.key === 's') {
      this.moveImages('blank');
    }
    if (event.key === 't') {
      this.moveImages('insufficient');
    }
    if (event.key === 'r') {
      this.moveImages('essay');
    }
    if (event.key === 'i') {
      this.moveImages('reescanear_qualidade');
    }
    if (event.key === 'c') {
      this.moveImages('reescanear_cortada');
    }
    if (event.key === 'm') {
      this.fetchImages();
    }
  }


  setAtalhoKeyUp = (event) => {
    if (event.shiftKey === false) {
      this.setState({
        shiftKey: false,
      });
    }
  }

  changeResultName = (name) => {
    if (name === 'blank') {
      return 'Sem texto';
    }
    if (name === 'essay') {
      return 'Redação';
    }
    if (name === 'insufficient') {
      return 'Insuficiente';
    }
    if (name === 'review') {
      return 'Review';
    }
    if (name === 'reescanear_qualidade') {
      return 'Imagem Ilegível';
    }
    if (name === 'reescanear_cortada') {
      return 'Imagem Cortada';
    }
  }

  stateForm() {
    const { formState, values } = this.state;
    return {
      ...values,
      ...formState.values,
    };
  }

  backPage() {
    const { filters, previous, page } = this.state;
    this.setState({ loadingFetchImages: true });
    const { headers } = getAxiosConfig();
    axios.get(`${previous}`,
      { params: { filters }, headers })
    .then((response) => {
      const images = [];
      for (let i = 0; i < response.data.results.length; i += 1) {
        images.push(response.data.results[i]);
      }
      const { next } = response.data.links;
      const previous = response.data.links.previos;
      const total_images = response.data.results;
      const { total_pages } = response.data;
      this.setState({
        images,
        total_images,
        next,
        previous,
        total_pages,
        page: page - 1,
        loadingFetchImages: false,
      });
    }).catch(handleRequestError);
  }

  nextPage() {
    const { filters, next, page } = this.state;
    this.setState({ loadingFetchImages: true });
    const { headers } = getAxiosConfig();
    axios.get(`${next}`,
      { params: { filters }, headers })
    .then((response) => {
      const images = [];
      for (let i = 0; i < response.data.results.length; i += 1) {
        images.push(response.data.results[i]);
      }
      const next = response.data.links.next;
      const { previous } = response.data.links;
      const total_images = response.data.results.length;
      const { total_pages } = response.data;
      this.setState({
        images,
        total_images,
        next,
        previous,
        total_pages,
        page: page + 1,
        loadingFetchImages: false,
      });
    }).catch(handleRequestError);
  }

  fetchImages() {
    const { filters, page } = this.state;
    this.setState({ loadingFetchImages: true });
    const { headers } = getAxiosConfig();
    axios.get(`${API_URL}/batimento/batimentos`,
      { params: { page, filters }, headers })
      .then((response) => {
        const images = [];
        for (let i = 0; i < response.data.results.length; i += 1) {
          images.push(response.data.results[i]);
        }
        const { next } = response.data.links;
        const { previous } = response.data.links;
        const total_images = response.data.count;
        const { total_pages } = response.data;
        this.setState({
          images,
          total_images,
          next,
          previous,
          total_pages,
          loadingFetchImages: false,
        });
      }).catch(handleRequestError);
  }

  moveImages(destination) {
    const { images } = this.state;
    const selectedImages = images.filter(element => element.check);

    if (selectedImages.length > 0) {
      this.setState({ loadingMoveImages: true });
      axios.post(`${API_URL}/batimento/move`, {
        images_processamento_id: selectedImages.map(element => element.processamento.id),
        destination,
        images_id: selectedImages.map(element => element.id),
      },
      getAxiosConfig())
      .then(() => {
        this.setState({ loadingMoveImages: false });
        this.fetchImages();
        toast.success('Imagem movida',
        {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(handleRequestError);
    } else {
      toast.error('Nenhuma imagem selecionada',
      {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  filterImages() {
    const { filterFormState } = this.state;
    const filters = Object.keys(filterFormState.values).map(key => (filterFormState.values[key]));
    this.setState({ filters, page: 1 },
      () => {
      this.fetchImages();
    });
  }

  toggleFilter() {
    const { toggle } = this.state;
    this.setState({
      toggle: !toggle,
    });
  }

  checkImage(idImage) {
    const { images, shiftKey, indexImgSelect } = this.state;
    const index = images.findIndex(element => element.processamento.id === idImage);
    const lastImgSelectCheked = !images[indexImgSelect] ? false : images[indexImgSelect].check;
    if (shiftKey && lastImgSelectCheked) {
      if (indexImgSelect <= index) {
        for (let i = indexImgSelect + 1; i <= index; i += 1) {
          this.checkImages(i, true);
        }
      } else {
        for (let i = index; i <= indexImgSelect - 1; i += 1) {
          this.checkImages(i, true);
        }
      }
    } else {
      this.checkImages(index, !images[index].check);
    }
    this.setState({
      indexImgSelect: index,
      images,
    });
  }

  checkImages(index, isCheck) {
    const { images } = this.state;
    images[index].check = isCheck;
    this.setState(images);
  }


  renderImgs() {
    const { images, loadingFetchImages } = this.state;
    if (loadingFetchImages) {
      return <Loading />;
    }
    return images.map(image => (
        <div key={image.processamento.id} className="col-3">
          <div role="button" tabIndex={0} onKeyPress={() => this.checkImage(image.processamento.id)} onClick={() => this.checkImage(image.processamento.id)} className={`lista-img-validar ${image.check ? 'active' : ''}`}>
            <figure>
              <img src={image.public_url} id="image-authorize" className="img-responsive" alt="Imagem da prova" />
            </figure>
            <figcaption>
                <div className="col-12">
                  <article className="text-left">
                    <span className="validador-txt-destaque">{image.processamento.co_inscricao}</span>
                  </article>
                </div>
                <div className="col-12">
                  <article className="text-left mt-2">
                    <span className="validador-txt-destaque-silver">Lote: </span>
                    <span>{image.processamento.batch}</span>
                  </article>
                </div>
                <div className="col-12">
                  <article className="text-left mt-2">
                    <span className="validador-txt-destaque-silver">De: </span>
                    <span>{this.changeResultName(image.prev_result)}</span>
                  </article>
                </div>
                <div className="col-12">
                  <article className="text-left mt-2">
                    <span className="validador-txt-destaque-silver">Para: </span>
                    <span>{this.changeResultName(image.result)}</span>
                  </article>
                </div>
            </figcaption>
          </div>
        </div>
    ));
  }

  render() {
    const {
      toggle,
      loadingMoveImages,
      total_images,
      total_pages,
      page,
    } = this.state;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="page-container-border">
            <section className="section-search">
              <div className={`filter mb-5 ${toggle ? 'show' : 'hidden'}`}>
                <div className="row">
                  <div className="col-9">
                    <Form
                      onChange={filterFormState => this.setState({ filterFormState })}
                      onValueChange={this.onValueChange}
                      getApi={(formApi) => { this.formApi = formApi; }}
                    >
                    <ul className="lista-select-filter">
                      <li>
                        <label>Situação</label>
                        <SelectInput
                          field="result"
                          options={BATIMENTO_FILTER.SITUACAO}
                        />
                      </li>
                      <li>
                        <button onClick={this.filterImages} type="submit" className="btn btn-primary mt-4" disabled={loadingMoveImages}>
                          Filtrar
                        </button>
                      </li>
                    </ul>
                    </Form>
                  </div>
                  <div className="col-3 pt-3 mt-4 w-100">
                    <div className="info-pagination p-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {
                        total_pages === 0 && (
                          <span>
                            Página: 0 de {total_pages}
                          </span>
                        )
                      }
                      {
                        total_pages !== 0 && (
                          <span>
                            Página {page} de {total_pages}
                          </span>
                        )
                      }
                      <span className="ml-4">
                        Total de imagens: {total_images}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="image-section">
                <div className="buttons-validate">
                  <ul className="validador-lista-actions">
                    <li>
                      <label className="mt-2 text-right">ALTERAR PARA:</label>
                    </li>
                    {
                      BATIMENTO_MOVE.SITUACAO.map(destination => (
                        <li key={destination.value}>
                          <button type="button" onClick={() => this.moveImages(destination.value)} className="btn btn-primary" disabled={loadingMoveImages}>{destination.label}</button>
                        </li>
                      ))
                    }
                    <li style={{ paddingLeft: 50 }}>
                      <label className="mt-2 text-right">REDIGITALIZAR:</label>
                    </li>
                    {
                      REESCANEAR_MOVE.SITUACAO.map(destination => (
                        <li key={destination.value}>
                          <button type="button" onClick={() => this.moveImages(destination.value)} className="btn btn-primary" disabled={loadingMoveImages}>{destination.label}</button>
                        </li>
                      ))
                    }
                  </ul>
                </div>
                <form>
                  <section className="validador-images">
                    <div className="row">
                      {
                        this.renderImgs()
                      }
                    </div>
                  </section>
                  <div className="text-right">
                    {
                      page > 1 && (
                        <button onClick={this.backPage} type="button" className="btn btn-primary mt-4">
                          Página anterior
                        </button>
                      )
                    }
                    {
                      page < total_pages && (
                      <button onClick={this.nextPage} type="button" className="btn btn-primary mt-4 ml-4">
                        Próxima página
                      </button>
                      )
                    }
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default ImagensBatidas;
