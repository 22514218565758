import React from 'react';
import PropTypes from 'prop-types';


const Loading = ({ children, loading }) => (
  <>
    {loading ? (
      <div className="loading fa-3x">
        <i className="fas fa-circle-notch fa-spin" />
      </div>
    ) : children}
  </>
);

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Loading;
