import React from 'react';
import PropTypes from 'prop-types';
import { debounce, get } from 'lodash';
import ImageClassification from '../components/ImageClassification';
import Card from '../../components/Card';
import {
  fetchUserBatch, fetchToClassifyImages, classifyImages, changeToClassify,
} from '../services/user_batch_service';
import ConfirmationClassifyModal from '../components/ConfirmationClassifyModal';

const RESULT_TRANSLATE = {
  essay: 'redação',
  insufficient: 'insuficiente',
  blank: 'em branco',
};

class UserBatchPage extends React.Component {
  state = {
    cropData: {},
    id: undefined,
    userBatch: {
      page: 1,
    },
    processResult: {
      label: 'Redação',
      value: 'essay',
    },
    showConfimationModal: false,
    destination: undefined,
    data: [],
    fetchingImages: false,
  }

  handleCrop = debounce((cropData, image, callback = () => null) => {
    const crop_data = JSON.stringify(cropData);
    changeToClassify(image.user_batch, image.id, { ...image, crop_data })
      .then(() => callback());
  }, 500)

  componentDidMount = () => {
    const { match: { params: { id } } } = this.props;

    this.setState({ id }, () => {
      this.fetchUserBatch();
      this.fetchToClassifyImages();
    });
  }

  fetchUserBatch = () => {
    const { id } = this.state;
    fetchUserBatch(id)
      .then((userBatch) => this.setState((prevState) => (
        { userBatch: { ...prevState.userBatch, ...userBatch } }
      )));
  }

  fetchToClassifyImages = () => {
    const { id, processResult } = this.state;
    this.setState({ fetchingImages: true });
    fetchToClassifyImages(id, { process_result: processResult.value, page_size: 32 })
      .then((data) => {
        const { total_pages, count } = data;
        const { results } = data;
        this.setState((prevState) => (
        {
          userBatch: {
            ...prevState.userBatch, total_images: count, total_pages,
          },
          data: results,
          fetchingImages: false,
        }
        ));
    });
  }

  handleChangeProcessResult = (processResult) => {
    this.setState({ processResult }, this.fetchToClassifyImages);
  }

  handleCheckImage = (checkedImage) => {
    this.setState((prevState) => (
      {
        data: prevState.data.map((image) => {
          if (image.id === checkedImage.id) {
            return { ...image, check: !image.check };
          }
          return image;
        }),
      }));
  }

  handleClassifyImages = (destination) => {
    const { processResult } = this.state;

    if (get(processResult, 'value') === 'review' || get(processResult, 'value') === 'manual' || get(processResult, 'value') === destination) {
      this.classifyImages(destination);
    } else {
      this.setState({ showConfimationModal: true, destination });
    }
  }

  classifyImages = (destination) => {
    const {
      id, data, processResult, cropData,
    } = this.state;
    const getMore = destination === 'get_more';
    const crop_data = JSON.stringify(cropData);
    const images = data.filter((im) => im.check || getMore).map((im) => im.id);
    this.setState({ fetchingImages: true });
    classifyImages(
      id,
      {
        images,
        crop_data,
        result: getMore ? processResult.value : destination,
      },
    ).then(() => this.fetchToClassifyImages(id))
      .catch(() => this.setState({ fetchingImages: false }));
  }

  render() {
    const {
      data, userBatch, processResult, destination, fetchingImages, showConfimationModal,
    } = this.state;

    return (
      <Card isFluid>
        <ImageClassification
          data={data}
          userBatch={userBatch}
          processResult={processResult}
          onChangeProcessResult={this.handleChangeProcessResult}
          onCheckImage={this.handleCheckImage}
          fetchingImages={fetchingImages}
          onClassifyImages={this.handleClassifyImages}
          onChangeCrop={this.handleCrop}
        />
        <ConfirmationClassifyModal
          visible={showConfimationModal}
          onClose={() => this.setState({ showConfimationModal: false })}
          result={RESULT_TRANSLATE[destination]}
          onClassify={() => { this.classifyImages(destination); this.setState({ showConfimationModal: false }); }}
        />
      </Card>
    );
  }
}

UserBatchPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default UserBatchPage;
