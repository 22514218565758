import React from 'react';
import PropTypes from 'prop-types';

const ClassificationButton = ({
  onClassifyImage, loadingClassifyImages, destination, className, color,
}) => (
  <li>
    <button
      type="button"
      className={className}
      disabled={loadingClassifyImages}
      onClick={() => onClassifyImage(destination.value)}
      style={{ background: color }}
    >
      {destination.label}
    </button>
  </li>
);

ClassificationButton.propTypes = {
  onClassifyImage: PropTypes.func.isRequired,
  loadingClassifyImages: PropTypes.bool.isRequired,
  destination: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

ClassificationButton.defaultProps = {
  className: 'btn btn-primary',
  color: '#218EC6',
};

export default ClassificationButton;
