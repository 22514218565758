import React from 'react';
import PropType from 'prop-types';
import Toolbar from './Toolbar';
import UserBatchInfo from './UserBatchInfo';

const ImageClassificationHeader = ({
  userBatch, onChangeProcessResult, processResult, onClassifyImages,
}) => (
  <>
    <UserBatchInfo
      userBatch={userBatch}
      onChangeProcessResult={onChangeProcessResult}
      processResult={processResult}
    />
    <Toolbar onClassifyImages={onClassifyImages} />
  </>
);

ImageClassificationHeader.propTypes = {
  userBatch: PropType.shape({
    batch_id: PropType.string,
  }),
  onChangeProcessResult: PropType.func.isRequired,
  onClassifyImages: PropType.func.isRequired,
  processResult: PropType.shape({
    label: PropType.string,
    value: PropType.string,
  }).isRequired,
};

ImageClassificationHeader.defaultProps = {
  userBatch: [],
};

export default ImageClassificationHeader;
