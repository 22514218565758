import React from 'react';
import PropTypes from 'prop-types';


class FooterTable extends React.Component {
  static propTypes = {
    media: PropTypes.string,
    mediaNal: PropTypes.string,
  };

  static defaultProps = {
    media: '-',
    mediaNal: '-',
  };

  render() {
    const { mediaNal } = this.props;
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <p>{mediaNal}</p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default FooterTable;
